import React, { useEffect } from 'react';
import './RangeSlider.scss';
import useDebounce from '../../../hooks/useDebounce';

export interface RangeSliderProps {
  value: number;
  min: number;
  max: number;
  label?: string;
  step?: number;
  disabled?: boolean;
  setValue: (value: number) => void;
  onSlidingDone?: () => void;
}

const RangeSlider = ({
  min,
  max,
  value = 0,
  step = 1,
  disabled,
  setValue,
  onSlidingDone
}: RangeSliderProps) => {
  const debounced = useDebounce(value, 500);

  const valueHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const sliderValue = parseInt(e.target.value);
    sliderValue >= min ? setValue(sliderValue) : setValue(min);
  };

  useEffect(() => {
    (debounced || debounced === 0) && onSlidingDone && onSlidingDone();
  }, [debounced]);

  const progressRange = `${(value * 100) / max}%`;

  return (
    <div className="range-slider">
      <input
        style={{
          backgroundSize: progressRange
        }}
        className={`${'range-slider-inner'} ${disabled ? 'disabled' : ''}`}
        onInput={valueHandler}
        type="range"
        min={0}
        max={max}
        value={value}
        step={step}
        disabled={disabled}
      />
    </div>
  );
};

export default RangeSlider;
