import React from 'react';
import Text from '../../../Text/Text';
import { ContractEvent } from '../../../../generated';
import { formatDateWithDot } from '../../../../utils/formatting.utils';

import './ContractEventLogItem.scss';

interface ContractEventProps {
  event: ContractEvent;
}

const ContractEventLogItem = ({ event }: ContractEventProps) => (
  <div className="contract-event-log-item" data-testid="contract-event-log-item">
    <Text as="span" category="label" size="large">
      {formatDateWithDot(event.date)}:
    </Text>
    <Text as="span">{event.description}</Text>
  </div>
);

export default ContractEventLogItem;
