import { useTranslation } from 'react-i18next';

import { ContractStatus, ContractType } from '../../generated';
import useNavigation from '../../hooks/useNavigation';
import Button from '../Button/Button';
import ContractStatusField from '../ContractStatusField/ContractStatusField';
import Text from '../Text/Text';

import './ContractItem.scss';

interface ContractItemProps {
  id?: string;
  status?: ContractStatus;
  type?: ContractType;
}

const ContractItem = ({
  id,
  status = ContractStatus.ContractSentToCustomer,
  type = ContractType.Loan
}: ContractItemProps) => {
  const { t } = useTranslation();
  const { goTo } = useNavigation();

  const onClickHandler = () => {
    goTo(`/admin/contract/${id}`);
  };

  return (
    <div className="contract-item-wrapper">
      <div className="contract-item-header">
        <Text as="h3" category="headline" size="large">
          {t(`admin.projectDetails.contracts.${type}`)}
        </Text>
        <ContractStatusField status={status} />
      </div>
      <Button size="large" variation="secondary" dataTest="" onClick={onClickHandler}>
        {t('admin.projectDetails.contracts.showDetails')}
      </Button>
    </div>
  );
};

export default ContractItem;
