import './FinancialOfferPreview.scss';
import { useTranslation } from 'react-i18next';
import InfoField from '../InfoField/InfoField';
import { Module, ModuleType, investerraModule } from '../../types/financialOffer.types';
import { format, toSwissFrancNotation } from '../../utils/formatting.utils';
import Text from '../Text/Text';
import Price from '../Price/Price';
import TooltipHeader from '../TooltipHeader/TooltipHeader';
import { FinancialOverview } from '../../store/project/project.types';
import { SHOW_NEW_UPLOAD_OFFER } from '../../utils/general.utils';

interface Props {
  status: 'indicative' | 'final';
  overview?: FinancialOverview;
  disabled?: boolean;
  modules?: Module[];
  className?: string;
  showModules?: boolean;
  showSubsidy?: boolean;
}

const FinancialOfferPreview = ({
  overview,
  disabled = false,
  modules,
  status,
  className,
  showModules
}: Props) => {
  const { t } = useTranslation();

  const classNameProps = `${className ? className : ''}`;
  const investerra = modules?.find((module) => module.id === investerraModule.id);

  const showExtendedPreview =
    (!SHOW_NEW_UPLOAD_OFFER && status === 'final') || status === 'indicative';
  return (
    <div className={classNameProps}>
      <TooltipHeader
        heading={`${status === 'indicative' ? t(`financingOfferStatus.${status}`) : ''} ${t(
          'financalOfferPreview.financingOffer'
        )}`}
        tooltip={{ text: t('financalOfferPreview.tooltipFinancingOffer') }}
      />
      <div className="fields">
        <div className="finance-wrapper">
          <InfoField
            className="field"
            label={`${
              status === 'indicative'
                ? `${t('financalOfferPreview.indicative.label')}${'\u00A0'}`
                : ''
            }${t('financalOfferPreview.objectPrice.label')}`}
            tooltipText={{
              heading: t('financalOfferPreview.objectPrice.label'),
              text:
                status === 'indicative'
                  ? t('financalOfferPreview.indicative.tooltip')
                  : t('financalOfferPreview.objectPrice.tooltip')
            }}
            disabled={disabled}>
            <Price as="p" category="headline" size="medium">
              {overview?.objectPrice ?? 0}
            </Price>
          </InfoField>
          {status === 'indicative' && (
            <InfoField
              className="field"
              label={t('financalOfferPreview.financialSupport.label')}
              tooltipText={{
                heading: t('financalOfferPreview.financialSupport.label'),
                text: t('financalOfferPreview.financialSupport.tooltip')
              }}
              disabled={disabled}>
              <Price as="p" category="headline" size="medium">
                {overview?.subsidy ?? 0}
              </Price>
            </InfoField>
          )}
          <InfoField
            className="field"
            label={t('financalOfferPreview.loan.label')}
            tooltipText={{
              heading: t('financalOfferPreview.loan.label'),
              text: t('financalOfferPreview.loan.tooltip')
            }}
            disabled={disabled}>
            <Price as="p" category="headline" size="medium">
              {overview?.loan ?? 0}
            </Price>
          </InfoField>
          <InfoField
            className="field"
            label={t('financalOfferPreview.baseFee.label')}
            tooltipText={{
              heading: t('financalOfferPreview.baseFee.label'),
              text: t('financalOfferPreview.baseFee.tooltip')
            }}
            disabled={disabled}>
            <Price as="p" category="headline" size="medium">
              {overview?.fee ?? 0}
            </Price>
          </InfoField>
          <InfoField
            className="field"
            label={t('financalOfferPreview.effectiveAnualIntrestRate.label')}
            tooltipText={{
              heading: t('financalOfferPreview.effectiveAnualIntrestRate.label'),
              text: t('financalOfferPreview.effectiveAnualIntrestRate.tooltip')
            }}
            disabled={disabled}>
            {`${overview?.effectiveAnnualInterestRate ?? 0} %`}
          </InfoField>
        </div>
        {modules && showModules && (
          <div className="modules-wrapper">
            {modules.length > 0 && showModules && (
              <TooltipHeader
                heading={t('offers.financialOfferPreview.extrasAndModules')}
                tooltip={{ text: t('offers.financialOfferPreview.extrasAndModulesTooltip') }}
              />
            )}
            {modules.map((module, index: number) => {
              return (
                <div key={index} className="field modules-field">
                  <InfoField
                    className="field"
                    label={t(`module.types.${module.type}`)}
                    tooltipText={{
                      heading: t(`module.types.${module.type}`),
                      text: t(`module.tooltips.${module.type}`)
                    }}
                    disabled={false}>
                    {module.type === ModuleType.INVESTERRA_ELECTRICITY_SALES
                      ? `Rp. ${module.value} pro ${t('units.kilowatt')}`
                      : `${toSwissFrancNotation(+module.value)} `}
                  </InfoField>
                </div>
              );
            })}
          </div>
        )}
        {showExtendedPreview && (
          <>
            <div className="general-data-wrapper">
              {/* <TooltipHeader heading={t('offers.keyFigures.heading')} /> */}
              <InfoField
                className="field"
                label={t('solarSystem.pvSystemOutput.label')}
                tooltipText={{
                  heading: t('solarSystem.pvSystemOutput.label'),
                  text: t('solarSystem.pvSystemOutput.tooltip')
                }}
                disabled={disabled}>
                {`${overview?.performance || 0} ${t('units.kilowattPerformance')}`}
              </InfoField>
              <InfoField
                className="field"
                label={t('solarSystem.consumptionPotential.label')}
                tooltipText={{
                  heading: t('solarSystem.consumptionPotential.label'),
                  text: t('solarSystem.consumptionPotential.tooltip')
                }}
                disabled={disabled}>
                {`${t(`solarSystem.consumptionPotential.prefix`)} ${
                  overview?.selfConsumptionPotential ?? 0
                } %`}
              </InfoField>
              <InfoField
                className="field"
                label={t('financalOfferPreview.co2Potential.label')}
                tooltipText={{
                  heading: t('financalOfferPreview.co2Potential.label'),
                  text: t('financalOfferPreview.co2Potential.tooltip')
                }}
                disabled={disabled}>
                {`${toSwissFrancNotation(overview?.potentialCO2Savings ?? 0, false)} ${t(
                  'units.kg'
                )}`}
              </InfoField>
              {status === 'indicative' && (
                <InfoField
                  className="field"
                  label={t('solarSystem.solarPotential.label')}
                  tooltipText={{
                    heading: t('solarSystem.solarPotential.label'),
                    text: t('solarSystem.solarPotential.tooltip')
                  }}
                  disabled={disabled}>
                  <Text as="p" category="headline" size="medium">
                    {`${toSwissFrancNotation(overview?.solarPotential ?? 0, false)} ${t(
                      'solarSystem.solarPotential.unit'
                    )}`}
                  </Text>
                </InfoField>
              )}
              {status === 'indicative' && (
                <InfoField
                  className="field"
                  label={t('solarSystem.sustainability.label')}
                  tooltipText={{
                    heading: t('solarSystem.sustainability.label'),
                    text: t('solarSystem.sustainability.tooltip')
                  }}
                  disabled={disabled}>
                  {overview?.sustainability && (
                    <Text as="p" category="headline" size="medium">
                      {t(`solarSystem.sustainability.${overview?.sustainability}`)}
                    </Text>
                  )}
                </InfoField>
              )}
            </div>
            <InfoField
              className="field"
              label={t('financalOfferPreview.feedInTariff.label')}
              tooltipText={{
                heading: t('financalOfferPreview.feedInTariff.label'),
                text: t('financalOfferPreview.feedInTariff.tooltip')
              }}
              disabled={disabled}>
              {overview?.feedInTariff && (
                <Text as="p" category="headline" size="medium">
                  {`${
                    investerra?.displayValue
                      ? format(investerra?.value as number)
                      : overview.feedInTariff
                  } ${t('units.rpKilowatt')}`}
                </Text>
              )}
            </InfoField>
            <InfoField
              className="field"
              label={t('financalOfferPreview.anualSavings.label')}
              tooltipText={{
                heading: t('financalOfferPreview.anualSavings.label'),
                text: t('financalOfferPreview.anualSavings.tooltip')
              }}
              disabled={disabled}>
              <Price as="p" category="headline" size="medium">
                {overview?.annualSavings ?? 0}
              </Price>
            </InfoField>
          </>
        )}
      </div>
    </div>
  );
};

export default FinancialOfferPreview;
