import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';

import Text from '../../../components/Text/Text';
import TextInput from '../../../components/Inputs/TextInput/TextInput';
import DatePicker from '../../../components/DatePicker/DatePicker';
import UploadFile from '../../UploadOffer/UploadFile/UploadFile';
import FileItem from '../../../components/File/FileItem/FileItem';
import Loader from '../../../components/Loader/Loader';

import { InstallationOfferCreate } from '../../../generated';
import { RootState } from '../../../reducers/rootReducer';
import { InstallationOfferEditProps } from '../InstallationOffer';
import { InstallationOfferActionType } from '../../../store/offers/offers.types';

import { handleInstallationOfferUpload } from '../../../store/offers/offers.action';

import { getHighlightClass } from '../../../utils/questionnaire.utils';
import { formatDate } from '../../../utils/formatting.utils';
import { validatePositiveNumber } from '../../../utils/validations.utils';
import { parseFilename } from '../../../utils/general.utils';
import { toastUtil } from '../../../utils/toast.utils';

import './EditInstallationOffer.scss';

const EditInstallationOffer = ({ isDataMissing }: InstallationOfferEditProps) => {
  const { t } = useTranslation();

  const { offer } = useSelector<RootState>(
    (state) => state.installationOfferReducer
  ) as InstallationOfferCreate;
  const dispatch = useDispatch();

  const [file, setFile] = useState<File | null>(null);
  const [loading, setLoading] = useState(false);

  const offerDate = offer?.date ? new Date(`${offer?.date}Z`) : null;
  useEffect(() => {
    uploadFile();
  }, [file]);

  const uploadFile = async () => {
    if (!file) {
      dispatch({
        type: InstallationOfferActionType.CHANGE_FILE_PATH,
        payload: undefined
      });
      return;
    }
    setLoading(true);

    const res = await handleInstallationOfferUpload(file);
    if (res?.data) {
      dispatch({
        type: InstallationOfferActionType.CHANGE_FILE_PATH,
        payload: res.data
      });

      setLoading(false);
    }
  };

  const selectFileHandler = (files: FileList | null) => {
    if (files && !!files.length) {
      const file = files[0];

      if (file?.type !== 'application/pdf') {
        toastUtil('error', t('genericErrors.onlyPdfSupported'), { autoClose: 5000 });
        return;
      }

      setFile(file);
    }
  };

  const removeFileHandler = () => {
    setFile(null);
  };

  const handleOfferPriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (+e.target.value < 0) return;

    dispatch({
      type: InstallationOfferActionType.CHANGE_OFFER_PRICE,
      payload: e.target.value
    });
  };

  const handleOfferDateChange = (date: Date | null) => {
    dispatch({
      type: InstallationOfferActionType.CHANGE_OFFER_DATE,
      payload: formatDate(date)
    });
  };

  return (
    <div>
      <Text as="h3" category="headline" size="large">
        {t('offers.offerDetails.heading')}
      </Text>
      <div className="installation-offer-form-section">
        <DatePicker
          selected={offerDate}
          setSelected={handleOfferDateChange}
          className={getHighlightClass(offerDate, isDataMissing)}
          label={t('offer.offerDate')}
        />
        <TextInput
          name="price"
          type="number"
          value={offer?.price}
          unit={t('currency.chf')}
          placeholder={t('offer.price')}
          onChange={handleOfferPriceChange}
          validate={validatePositiveNumber}
          required
        />
        {file && (
          <FileItem
            name={parseFilename(file?.name) as string}
            onDelete={removeFileHandler}
            variation="primary"
            className="file-item-display"
          />
        )}
        {!file && (
          <div className="installation-offer-file-wrapper">
            <UploadFile handleChangeFiles={selectFileHandler} acceptFileType="pdf" />
            <div className="qr-code-wrapper">
              <Text as="h2" category="headline" size="medium">
                {t('uploadOffer.title')}
              </Text>
              <Text as="p">{t('uploadOffer.qr.description.doNotHaveOfferOficial')}</Text>
              <Text as="p">{t('uploadOffer.qr.description.scanQrCode')}</Text>
              <div className="qr-code">
                <QRCode value={`${window.location.href}`} />
              </div>
            </div>
          </div>
        )}
      </div>
      {loading && <Loader />}
    </div>
  );
};

export default EditInstallationOffer;
