import { t } from 'i18next';
import React from 'react';
import { ReactDatePickerCustomHeaderProps } from 'react-datepicker';
import { getYear, getMonth } from 'date-fns';
import { generateYearArray, generateDropdownValues } from '../../utils/general.utils';
import { Month } from '../../types/util.types';
import Icon from '../Icon/Icon';

interface CustomDatePickerHeaderProps extends ReactDatePickerCustomHeaderProps {
  fromYears?: number;
}

const CustomDatePickerHeader = ({
  date,
  changeYear,
  changeMonth,
  decreaseMonth,
  increaseMonth,
  decreaseYear,
  increaseYear,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
  prevYearButtonDisabled,
  nextYearButtonDisabled,
  fromYears = 1920
}: CustomDatePickerHeaderProps) => {
  const years = generateYearArray(fromYears).map((year) => year);
  const months = generateDropdownValues(Month, 'months');

  return (
    <div className="calendar-header">
      <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
        <Icon name={'arrow-back'} />
      </button>
      <select
        value={months.map((m) => m.value)[getMonth(date)]}
        onChange={({ target: { value } }) =>
          changeMonth(months.findIndex((month) => month.value === value))
        }>
        {months.map((m) => (
          <option key={m.value} value={m.value}>
            {t(`${m.displayValue}`)}
          </option>
        ))}
      </select>
      <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
        <Icon name={'arrow-forward'} />
      </button>
      <button onClick={decreaseYear} disabled={prevYearButtonDisabled}>
        <Icon name={'arrow-back'} />
      </button>
      <select
        value={getYear(date)}
        onChange={({ target: { value } }) => changeYear(parseFloat(value))}>
        {years.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
      <button onClick={increaseYear} disabled={nextYearButtonDisabled}>
        <Icon name={'arrow-forward'} />
      </button>
    </div>
  );
};

export default CustomDatePickerHeader;
