import { useTranslation } from 'react-i18next';
import Button from '../../../../../components/Button/Button';
import TableHead from '../../../../../components/Tables/TableHead/TableHead';
import Text from '../../../../../components/Text/Text';
import { Get4EyeProjectDetails } from '../../../../../store/fourEyeCheck/fourEyeCheck.types';
import { getValueOrPlaceholder } from '../../../../../utils/general.utils';

enum KycPersonalDetailsTableHeading {
  TITLE = 'title',
  DATA = 'data'
}

interface KycPersonalDetailsTableProps {
  projectDetails?: Get4EyeProjectDetails;
  toggle: () => void;
}

const KycPersonalDetailsTable = ({ projectDetails, toggle }: KycPersonalDetailsTableProps) => {
  const { t } = useTranslation();
  const tableHeadings = [...Object.values(KycPersonalDetailsTableHeading).map((elem) => elem)];

  const data = [
    {
      name: 'firstName',
      value: projectDetails?.kYCResultsData.personalDataKYC?.firstName
    },
    {
      name: 'lastName',
      value: projectDetails?.kYCResultsData.personalDataKYC?.lastName
    },
    {
      name: 'gender',
      value: projectDetails?.kYCResultsData.personalDataKYC?.gender || '-'
    },
    {
      name: 'maritalStatus',
      value: projectDetails?.creditCheckData?.personalDataCreditCheckForKYC?.maritalStatus
    },
    {
      name: 'nationality',
      value: projectDetails?.kYCResultsData.personalDataKYC.nationality
    },
    {
      name: 'householdType',
      value: projectDetails?.creditCheckData?.personalDataCreditCheckForKYC?.householdType
    },
    {
      name: 'householdMonthlyInsuranceFee',
      value:
        projectDetails?.creditCheckData?.personalDataCreditCheckForKYC?.householdMonthlyInsuranceFee
    },
    {
      name: 'street',
      value: projectDetails?.kYCResultsData.personalDataKYC.address.street
    },
    {
      name: 'houseNumber',
      value: projectDetails?.kYCResultsData.personalDataKYC.address.houseNumber
    },
    {
      name: 'postalCode',
      value: projectDetails?.kYCResultsData.personalDataKYC.address.postalCode
    },
    {
      name: 'city',
      value: projectDetails?.kYCResultsData.personalDataKYC.address.municipality
    },
    {
      name: 'canton',
      value: projectDetails?.kYCResultsData.personalDataKYC.address.canton
    },
    {
      name: 'country',
      value: projectDetails?.kYCResultsData.personalDataKYC.address.country
    },
    {
      name: 'residenceType',
      value: projectDetails?.creditCheckData?.personalDataCreditCheckForKYC?.residenceType
    }
  ];

  return (
    <div className="personal-details-table-wrapper">
      <TableHead
        values={tableHeadings}
        columnWidths={[50, 50]}
        translationKey="admin.projectDetails.projectReview.personalDetailsTable.table.heading">
        {data.map((elem, ind) => (
          <tr key={ind}>
            <Text as="td" category="label" size="large">
              {t(
                `admin.projectDetails.projectReview.kycPersonalDetailsTable.table.data.${elem.name}`
              )}
            </Text>
            <Text as="td" category="label" size="large">
              {getValueOrPlaceholder(elem.value)}
            </Text>
          </tr>
        ))}
      </TableHead>
      <Button variation="primary" size="large" dataTest="" onClick={toggle}>
        {t('close')}
      </Button>
    </div>
  );
};

export default KycPersonalDetailsTable;
