import { HttpStatusCode } from 'axios';
import api from '../../api/api';
import { toastUtil } from '../../utils/toast.utils';
import { previewFile } from '../../utils/general.utils';
import { ContractApiFp, ContractCreate } from '../../generated';

const { createContract, getContractById, getContracts, fetchContractDocumentFile } =
  ContractApiFp();

export const getAllContracts = async () => {
  try {
    const getContractsRequest = await getContracts('fakeProjectId');
    const res = await getContractsRequest();
    return res.data;
  } catch (error) {
    if (error instanceof Error) {
      toastUtil('error', error.message);
    }
    return [];
  }
};

export const getContract = async (id: string) => {
  try {
    const getContractByIdRequest = await getContractById(id);
    const res = await getContractByIdRequest();
    return res.data;
  } catch (error) {
    if (error instanceof Error) {
      toastUtil('error', error.message);
    }
    return null;
  }
};

export const getContractDocument = async (id: string, path: string) => {
  try {
    const fetchContractDocumentFileRequest = await fetchContractDocumentFile(id, path);
    const res = await fetchContractDocumentFileRequest();
    previewFile(res.data);
  } catch (error) {
    if (error instanceof Error) {
      toastUtil('error', error.message);
    }
  }
};

export const createContractHandler = async (file: File[], data: ContractCreate) => {
  try {
    const createContractRequest = await createContract('fakeProjectId', file, data);
    const res = await createContractRequest();
    return res.data;
  } catch (error) {
    if (error instanceof Error) {
      toastUtil('error', error.message);
    }
    return null;
  }
};

export const uploadContract = async (
  contractId: string | number,
  files: FileList
): Promise<boolean> => {
  try {
    const file = files[0];
    const formData = new FormData();
    formData.append('file', file);

    const response = await api.post(`/contracts/${contractId}/admin/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.status == HttpStatusCode.Ok;
    /* eslint-disable  @typescript-eslint/no-explicit-any */
  } catch (err: any) {
    toastUtil('error', err.message);
    return false;
  }
};

export const uploadOrderConfirmation = async (
  projectId: string | number,
  file: File
): Promise<boolean> => {
  try {
    const formData = new FormData();
    formData.append('file', file);

    const response = await api.post(
      `/contracts/user/project/${projectId}/order-confirmation-upload`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );
    return response.status == HttpStatusCode.Ok;
    /* eslint-disable  @typescript-eslint/no-explicit-any */
  } catch (err: any) {
    toastUtil('error', err.message);
    return false;
  }
};

export const downloadOrderConfirmation = async (projectId: string | number) => {
  try {
    const response = await api.get(`/contracts/project/${projectId}/order-confirmation-download`, {
      responseType: 'blob'
    });
    previewFile(response.data);
    /* eslint-disable  @typescript-eslint/no-explicit-any */
  } catch (error: any) {
    toastUtil('error', error.message);
  }
};
