import { useEffect } from 'react';
import i18n, { Languages } from '../../i18n/i18n';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import './BasicPageLayout.scss';
import useTracking from '../../hooks/useTracking';
import { User } from '../../store/user/types';
import { useSelector } from 'react-redux';
import { RootState } from '../../reducers/rootReducer';
import { useNavigate, Outlet, useLocation } from 'react-router-dom';

interface Props {
  className?: string | undefined;
}

const BasicPageLayout = ({ className }: Props) => {
  const userReducer = useSelector<RootState>((state) => state.userReducer) as User;
  const navigate = useNavigate();
  const location = useLocation();

  const { setPathPageView, setIdentity, sendGAPageview } = useTracking();

  const supportedLanguages = Object.values(Languages) as string[];

  useEffect(() => {
    if (process.env.REACT_APP_ENABLE_TRACKING?.toString() === 'enable') {
      const path = location.pathname + location.search;
      sendGAPageview(path);
      setPathPageView(path);
      userReducer.email && setIdentity(userReducer.email);
    }
  }, [location]);

  useEffect(() => {
    const language = i18n.language;
    const urlPathParts = location.pathname.split('/').filter((part) => part !== '');

    const getLanguagePrefixedPath = () => {
      if (!urlPathParts.length) return `/${language}`;

      if (urlPathParts.length && !supportedLanguages.includes(urlPathParts[0])) {
        return `/${language}/${urlPathParts.join('/')}`;
      } else {
        i18n.changeLanguage(urlPathParts[0]);
        return location;
      }
    };

    navigate(getLanguagePrefixedPath());
  }, []);

  return (
    <div className={`layout-wrapper ${className}`}>
      <div className="header-wrapper">
        <Header />
      </div>
      <div className="main-wrapper">
        <>
          <Breadcrumbs />
          <Outlet />
        </>
      </div>
      <Footer />
    </div>
  );
};

export default BasicPageLayout;
