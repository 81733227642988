import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import Button from '../../components/Button/Button';
import Icon from '../../components/Icon/Icon';
import Text from '../../components/Text/Text';

import useNavigation from '../../hooks/useNavigation';

import { hubspotLinks } from '../../utils/hubspotLinks';

import './StartFinancing.scss';

const StartFinancing = () => {
  const { t } = useTranslation();
  const { projectUuid } = useParams();
  const { goTo } = useNavigation();

  const onClickHandler = () => {
    if (projectUuid) {
      goTo(`/questionnaire/${projectUuid}`);
      return;
    }
    goTo('/my-profile/projects');
  };

  return (
    <div className="start-financing-wrapper">
      <Text as="h2" category="display" variety={2}>
        {t('confirmFinancing.title')}
      </Text>
      <Icon name="handshake" />
      <Text category="body" size="large">
        {t('confirmFinancing.thankYouForYourInterest')}
      </Text>
      <Text category="body" size="large">
        {t('confirmFinancing.nextStep')}
      </Text>
      <Text category="body" size="large">
        {t('confirmFinancing.startQuestionnaire')}
      </Text>
      <Text category="body" size="large">
        <Trans
          i18nKey="confirmFinancing.contactUs"
          t={t}
          components={{
            mailtoLink: <a href={`mailto:${hubspotLinks.supportEmail}`} className="contact-link" />,
            phoneNumber: (
              <a href={`tel:${t('investerraDetails.support.phone')}`} className="contact-link" />
            )
          }}
        />
      </Text>
      <Button variation="primary" size="large" onClick={onClickHandler} dataTest="">
        {t('confirmFinancing.getStarted')}
      </Button>
    </div>
  );
};

export default StartFinancing;
