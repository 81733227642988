import {
  InstallationOfferCreate,
  InstallationPersonalDetails,
  InstallationBuilding,
  InstallationPartner,
  InstallationCompany,
  InstallationLoan
} from '../generated/api';
import { InstallationOfferActionType } from '../store/offers/offers.types';

type FieldValuePayload<T> = {
  field: keyof T;
  value: string;
};

export const initialState: InstallationOfferCreate | null = {
  personalDetails: {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    building: {
      sepId: '',
      latitude: 0,
      longitude: 0,
      address: {
        street: '',
        houseNumber: '',
        postalCode: 0,
        municipality: ''
      }
    }
  },
  installationPartner: {
    firstName: '',
    lastName: ''
  },
  installationCompany: {
    name: '',
    type: '',
    uid: '',
    email: '',
    address: {
      street: '',
      houseNumber: '',
      postalCode: 0,
      municipality: ''
    }
  },
  loan: {
    deposit: '0',
    termYears: ''
  },
  offer: {
    date: '',
    price: ''
  },
  filePath: ''
};

export type UploadOfferFormAction =
  | {
      type: InstallationOfferActionType.REPLACE_STATE;
      payload: InstallationOfferCreate;
    }
  | {
      type: InstallationOfferActionType.CHANGE_PERSONAL_DETAILS;
      payload: FieldValuePayload<InstallationPersonalDetails>;
    }
  | {
      type: InstallationOfferActionType.CHANGE_BUILDING;
      payload: InstallationBuilding | undefined;
    }
  | {
      type: InstallationOfferActionType.CHANGE_INSTALLATION_PARTNER;
      payload: FieldValuePayload<InstallationPartner>;
    }
  | {
      type: InstallationOfferActionType.CHANGE_INSTALLATION_COMPANY;
      payload: FieldValuePayload<InstallationCompany>;
    }
  | {
      type: InstallationOfferActionType.CHANGE_INSTALLATION_COMPANY_ZEFIX_DATA;
      payload: Partial<InstallationCompany>;
    }
  | {
      type: InstallationOfferActionType.CLEAR_INSTALLATION_COMPANY;
    }
  | {
      type: InstallationOfferActionType.CHANGE_OFFER_PRICE;
      payload: string;
    }
  | {
      type: InstallationOfferActionType.CHANGE_OFFER_DATE;
      payload: string;
    }
  | {
      type: InstallationOfferActionType.CHANGE_LOAN_DETAILS;
      payload: {
        key: keyof InstallationLoan;
        value: string;
      };
    }
  | {
      type: InstallationOfferActionType.CHANGE_FILE_PATH;
      payload: string;
    };

const installationOfferReducer = (
  state: InstallationOfferCreate | null = initialState,
  action: UploadOfferFormAction
) => {
  switch (action.type) {
    case InstallationOfferActionType.REPLACE_STATE:
      return {
        ...action.payload
      };
    case InstallationOfferActionType.CHANGE_PERSONAL_DETAILS:
      return {
        ...state,
        personalDetails: {
          ...state?.personalDetails,
          [action.payload.field]: action.payload.value
        }
      };
    case InstallationOfferActionType.CHANGE_BUILDING:
      return {
        ...state,
        personalDetails: {
          ...state?.personalDetails,
          building: action.payload
        }
      };
    case InstallationOfferActionType.CHANGE_INSTALLATION_PARTNER:
      return {
        ...state,
        installationPartner: {
          ...state?.installationPartner,
          [action.payload.field]: action.payload.value
        }
      };
    case InstallationOfferActionType.CHANGE_INSTALLATION_COMPANY:
      return {
        ...state,
        installationCompany: {
          ...state?.installationCompany,
          [action.payload.field]: action.payload.value
        }
      };
    case InstallationOfferActionType.CHANGE_INSTALLATION_COMPANY_ZEFIX_DATA:
      return {
        ...state,
        installationCompany: {
          ...state?.installationCompany,
          ...action.payload
        }
      };
    case InstallationOfferActionType.CLEAR_INSTALLATION_COMPANY:
      return {
        ...state,
        installationCompany: undefined
      };
    case InstallationOfferActionType.CHANGE_OFFER_PRICE:
      return {
        ...state,
        offer: {
          ...state?.offer,
          price: action.payload
        }
      };
    case InstallationOfferActionType.CHANGE_OFFER_DATE:
      return {
        ...state,
        offer: {
          ...state?.offer,
          date: action.payload
        }
      };
    case InstallationOfferActionType.CHANGE_LOAN_DETAILS:
      return {
        ...state,
        loan: {
          ...state?.loan,
          [action.payload.key]: action.payload.value
        }
      };
    case InstallationOfferActionType.CHANGE_FILE_PATH:
      return {
        ...state,
        filePath: action.payload
      };
    default:
      return state;
  }
};

export default installationOfferReducer;
