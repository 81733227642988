import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TabContent from '../../../../components/Tabs/TabContent/TabContent';
import Tabs from '../../../../components/Tabs/Tabs';
import Text from '../../../../components/Text/Text';
import BankDetails from '../../../../components/BankDetails/BankDetails';
import { Profile, UpdateProfile } from '../../../../store/profile/profile.types';
import { getClientProfile, updateProfile } from '../../../../store/profile/profile.actions';
import PersonalDataForm from '../../../../components/PersonalDataForm/PersonalDataForm';
import { useParams } from 'react-router-dom';
import { AuthContext } from '../../../../context/AuthContextProvider';
import { DeepPartial } from '../../../../types/util.types';
import './ClientDetails.scss';

const ClientDetails = () => {
  const { t } = useTranslation();
  const { clientId } = useParams();

  const auth = useContext(AuthContext);

  const [clientDetails, setClientDetails] = useState<DeepPartial<Profile>>({});

  const user = clientDetails.profile ?? {};

  useEffect(() => {
    fetchClientDetails();
  }, [auth?.isAuthenticated]);

  const fetchClientDetails = async () => {
    if (!clientId) return;
    const data = await getClientProfile(clientId);
    if (data) setClientDetails(data);
  };

  const updateBankDetails = async (data: Partial<UpdateProfile>) => {
    const resp = await updateProfile(data, `${clientDetails.profile?.userUuid}`);
    if (resp) {
      fetchClientDetails();
    }
  };

  return (
    <>
      <Text as={'h1'} category={'display'} className={'personal-data-title'}>
        {t('admin.clients.clientDetails')}
      </Text>
      <Tabs>
        <TabContent title={t(`admin.clients.clientData`)} className={'personal-data-wrapper'}>
          <PersonalDataForm
            data={clientDetails}
            setData={setClientDetails}
            onSubmit={(data) => {
              clientId && updateProfile(data, clientId);
            }}
          />
        </TabContent>
        <TabContent title={t(`bankDetails`)} className="personal-data-wrapper">
          <BankDetails
            className="personal-data-form"
            user={user}
            updateBankDetails={updateBankDetails}
          />
        </TabContent>
      </Tabs>
    </>
  );
};

export default ClientDetails;
