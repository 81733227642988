import './PartnerInfo.scss';
import { useTranslation } from 'react-i18next';
import Text from '../../../../components/Text/Text';
import TextInput from '../../../../components/Inputs/TextInput/TextInput';
import Checklist from '../../../../components/Checklist/Checklist';
import {
  PartnerCompany,
  PartnerProduct,
  products
} from '../../../../store/partners/partners.types';
import { useEffect, useState } from 'react';
import { updatePartnerEmail } from '../../../../store/partners/partners.action';
import Button from '../../../../components/Button/Button';
import { toastUtil } from '../../../../utils/toast.utils';

interface PartnerInfoProps {
  partnerData: PartnerCompany;
  partnerId?: string;
}

const PartnerInfo = ({ partnerData, partnerId }: PartnerInfoProps) => {
  const { t } = useTranslation();

  const [partnerEmail, setPartnerEmail] = useState('');

  const updateEmail = async () => {
    const data = partnerId && (await updatePartnerEmail(+partnerId, partnerEmail as string));
    data && toastUtil('success', t('changesSaved'));
  };

  useEffect(() => {
    if (partnerData.profile?.email) {
      setPartnerEmail(partnerData.profile.email);
    } else {
      setPartnerEmail(partnerData.contactEmail as string);
    }
  }, [partnerData]);

  return (
    <div className="partner-info-wrapper">
      {partnerData && (
        <>
          <div className="partner-data">
            <Text as="h3" category="headline" size="large">
              {t('partnerDetails.partnerInfo.companyData')}
            </Text>
            <TextInput
              name="companyName"
              type="text"
              disabled
              placeholder={t('companyName')}
              value={partnerData.name}
            />
            <TextInput
              name="fistName"
              type="text"
              disabled
              placeholder={t('name')}
              value={partnerData.contactFirstName}
            />
            <TextInput
              name="lastName"
              type="text"
              disabled
              placeholder={t('lastName')}
              value={partnerData.contactLastName}
            />
            <TextInput
              onChange={(e) => {
                setPartnerEmail(e.target.value);
              }}
              name="partnerEmail"
              type="text"
              disabled={!!partnerData.profile}
              placeholder={t('email')}
              value={partnerEmail}
            />
            {partnerData?.partnerLocations && !!partnerData?.partnerLocations.length && (
              <TextInput
                name="partnerLocation"
                type="text"
                disabled
                placeholder={t('companyHeadquarters')}
                value={`${partnerData.partnerLocations[0].address}, ${partnerData.partnerLocations[0].postalCode} ${partnerData.partnerLocations[0].municipality}`}
              />
            )}
            <TextInput
              name="partnerPhoneNumber"
              type="text"
              disabled
              placeholder={t('phoneNumber')}
              value={partnerData.contactPhoneNumber}
            />
            <TextInput
              name="partnerLevel"
              type="text"
              disabled
              placeholder={t('level')}
              value={t(`partnerLevel.${partnerData?.partnerLevel?.partnerLevel}`)}
            />
            {!partnerData.profile && (
              <Button variation="primary" size="large" dataTest="" onClick={updateEmail}>
                {t('saveChanges')}
              </Button>
            )}
          </div>
          <div className="partner-product">
            <Text as="h3" category="headline" size="large">
              {t('partnerDetails.partnerInfo.productRange')}
            </Text>
            <Checklist
              items={products.map((p) => {
                return { ...p, label: t(p.label) };
              })}
              checked={partnerData.modules ? (partnerData?.modules as PartnerProduct[]) : []}
              disabled
              onCheck={() => {
                // TBD
                return;
              }}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default PartnerInfo;
