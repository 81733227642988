import { SetStateType, SwissCanton } from '../../types/util.types';
import { BankDetailsType } from '../profile/profile.types';

export interface QuestionnaireType {
  kycData: KYCQuestionaireData;
  creditCheckData: CreditCheckQuestionaireData;
}

export interface KYCQuestionaireData {
  dateOfBirth: Date;
  placeOfBirth: string;
  address: Address;
  gender: GenderType;
  nationality: Country;
  phoneNumber: string;
  bankDetails: BankDetailsType;
}

export interface CreditCheckQuestionaireData {
  applicant: ApplicantData;
  household: HouseholdData;
  householdPartner: HouseholdPartnerData;
}

export interface ApplicantData {
  financialData: Partial<FinancialData>;
  employmentStatus?: EmploymentStatus;
  maritalStatus?: MaritalStatus;
  residenceType?: HousingReference;
  residingSinceYear?: number;
  residingSinceMonth?: number;
  beneficialOwner: boolean;
  permitType?: PermitType;
}

export interface FinancialData {
  isChurchTaxObligatory?: boolean;
  numberOfMonthlyIncomeAdditional?: 12 | 13;
  transportationType?: TransportType;
  monthlyIncomeGrossAmountAdditional: number[];
  numberOfMonthlyIncomeMain?: 12 | 13;
  monthlyTransportationCosts?: number;
  monthlyExpensesAlimony?: number;
  monthlyEducationCosts?: number;
  monthlyOtherFixExpenses?: number;
  monthlyOneTimeExpenses?: number;
  workingDaysPerMonth?: number;
  monthlyHousingCost?: number;
  monthlyIncomeGrossAmountMain?: number;
}

export interface HouseholdData {
  childrenInHouseholdAge: number[];
  householdType?: HouseholdType;
  householdNumberOfSupportedGrownUps?: number;
  householdMonthlyInsuranceFee?: number;
}

export interface HouseholdPartnerData {
  address?: Address;
  employmentStatus?: EmploymentStatus;
  financialData?: FinancialData;
  maritalStatus?: MaritalStatus;
  dateOfBirth?: Date;
}

export interface Address {
  street: string;
  houseNumber: string;
  postalCode: string;
  municipality?: string;
  canton?: SwissCanton;
  country?: Country;
}

export interface CityAndCanton {
  municipality: string;
  canton: SwissCanton;
  country: Country;
}

export interface QuestionnaireFormProps {
  isDataMissing: boolean;
  setDisableSave: SetStateType<boolean>;
  onSave?: (data: QuestionnaireType) => void;
}

export enum EmploymentStatus {
  EMPLOYED = 'EMPLOYED',
  UNEMPLOYED = 'UNEMPLOYED',
  SELF_EMPLOYED = 'SELF_EMPLOYED',
  STUDENT = 'STUDENT',
  PENSION = 'PENSION',
  TEMPORARY_EMPLOYMENT = 'TEMPORARY_EMPLOYMENT',
  HOUSE_WIFE_MAN = 'HOUSE_WIFE_MAN'
}

export enum MaritalStatus {
  SINGLE = 'SINGLE',
  MARRIED = 'MARRIED',
  DIVORCED = 'DIVORCED',
  WIDOWED = 'WIDOWED',
  SEPARATED = 'SEPARATED',
  REGISTERED = 'REGISTERED',
  JURIDICAL_RESOLVED = 'JURIDICAL_RESOLVED'
}

export enum TransportType {
  NONE = 'NONE',
  BICYCLE = 'BICYCLE',
  MOPED = 'MOPED',
  MOTORCYCLE = 'MOTORCYCLE',
  CAR = 'CAR',
  PUBLIC = 'PUBLIC'
}

export enum HouseholdType {
  SINGLE = 'SINGLE',
  MARRIED_COUPLE = 'MARRIED_COUPLE',
  SINGLE_HOUSEHOLD_COMMUNITY = 'SINGLE_HOUSEHOLD_COMMUNITY',
  SINGLE_PARENT = 'SINGLE_PARENT',
  SINGLE_SUPPORTING_GROWNUP_IN_SAME_HOUSEHOLD = 'SINGLE_SUPPORTING_GROWNUP_IN_SAME_HOUSEHOLD',
  SINGLE_PARENT_HOUSEHOLD_COMMUNITY = 'SINGLE_PARENT_HOUSEHOLD_COMMUNITY'
}

export enum AddressType {
  CURRENT = 'CURRENT',
  MAILING_CORRESPONDENCE = 'MAILING_CORRESPONDENCE',
  PREVIOUS = 'PREVIOUS',
  EMPLOYMENT = 'EMPLOYMENT',
  TEMPORARY = 'TEMPORARY'
}

export enum GenderType {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  OTHER = 'OTHER'
}

export enum HousingReference {
  FAMILY = 'FAMILY',
  EMPLOYER = 'EMPLOYER',
  OWNER_WITH_MORTGAGE = 'OWNER_WITH_MORTGAGE',
  OWNER_WITHOUT_MORTGAGE = 'OWNER_WITHOUT_MORTGAGE',
  RENTER = 'RENTER'
}

export enum PermitType {
  B = 'B',
  B_EU = 'B_EU',
  C = 'C',
  C_EU = 'C_EU'
}

export enum Country {
  SWITZERLAND = 'SWITZERLAND',
  GERMANY = 'GERMANY',
  AUSTRIA = 'AUSTRIA',
  ITALY = 'ITALY',
  FRANCE = 'FRANCE',
  LIECHTENSTEIN = 'LIECHTENSTEIN',
  AFGHANISTAN = 'AFGHANISTAN',
  ALBANIA = 'ALBANIA',
  ALGERIA = 'ALGERIA',
  ANDORRA = 'ANDORRA',
  ANGOLA = 'ANGOLA',
  ANTIGUA_AND_DEPS = 'ANTIGUA_AND_DEPS',
  ARGENTINA = 'ARGENTINA',
  ARMENIA = 'ARMENIA',
  AUSTRALIA = 'AUSTRALIA',
  AZERBAIJAN = 'AZERBAIJAN',
  BAHAMAS = 'BAHAMAS',
  BAHRAIN = 'BAHRAIN',
  BANGLADESH = 'BANGLADESH',
  BARBADOS = 'BARBADOS',
  BELARUS = 'BELARUS',
  BELGIUM = 'BELGIUM',
  BELIZE = 'BELIZE',
  BENIN = 'BENIN',
  BHUTAN = 'BHUTAN',
  BOLIVIA = 'BOLIVIA',
  BOSNIA_HERZEGOVINA = 'BOSNIA_HERZEGOVINA',
  BOTSWANA = 'BOTSWANA',
  BRAZIL = 'BRAZIL',
  BRUNEI = 'BRUNEI',
  BULGARIA = 'BULGARIA',
  BURKINA = 'BURKINA',
  BURUNDI = 'BURUNDI',
  CAMBODIA = 'CAMBODIA',
  CAMEROON = 'CAMEROON',
  CANADA = 'CANADA',
  CAPE_VERDE = 'CAPE_VERDE',
  CENTRAL_AFRICAN_REP = 'CENTRAL_AFRICAN_REP',
  CHAD = 'CHAD',
  CHILE = 'CHILE',
  CHINA = 'CHINA',
  COLOMBIA = 'COLOMBIA',
  COMOROS = 'COMOROS',
  CONGO = 'CONGO',
  CONGO_DEMOCRATIC_REP = 'CONGO_DEMOCRATIC_REP',
  COSTA_RICA = 'COSTA_RICA',
  CROATIA = 'CROATIA',
  CUBA = 'CUBA',
  CYPRUS = 'CYPRUS',
  CZECH_REPUBLIC = 'CZECH_REPUBLIC',
  DENMARK = 'DENMARK',
  DJIBOUTI = 'DJIBOUTI',
  DOMINICA = 'DOMINICA',
  DOMINICAN_REPUBLIC = 'DOMINICAN_REPUBLIC',
  EAST_TIMOR = 'EAST_TIMOR',
  ECUADOR = 'ECUADOR',
  EGYPT = 'EGYPT',
  EL_SALVADOR = 'EL_SALVADOR',
  EQUATORIAL_GUINEA = 'EQUATORIAL_GUINEA',
  ERITREA = 'ERITREA',
  ESTONIA = 'ESTONIA',
  ETHIOPIA = 'ETHIOPIA',
  FIJI = 'FIJI',
  FINLAND = 'FINLAND',
  GABON = 'GABON',
  GAMBIA = 'GAMBIA',
  GEORGIA = 'GEORGIA',
  GHANA = 'GHANA',
  GREECE = 'GREECE',
  GRENADA = 'GRENADA',
  GUATEMALA = 'GUATEMALA',
  GUINEA = 'GUINEA',
  GUINEA_BISSAU = 'GUINEA_BISSAU',
  GUYANA = 'GUYANA',
  HAITI = 'HAITI',
  HONDURAS = 'HONDURAS',
  HUNGARY = 'HUNGARY',
  ICELAND = 'ICELAND',
  INDIA = 'INDIA',
  INDONESIA = 'INDONESIA',
  IRAN = 'IRAN',
  IRAQ = 'IRAQ',
  IRELAND_REPUBLIC = 'IRELAND_REPUBLIC',
  ISRAEL = 'ISRAEL',
  IVORY_COAST = 'IVORY_COAST',
  JAMAICA = 'JAMAICA',
  JAPAN = 'JAPAN',
  JORDAN = 'JORDAN',
  KAZAKHSTAN = 'KAZAKHSTAN',
  KENYA = 'KENYA',
  KIRIBATI = 'KIRIBATI',
  KOREA_NORTH = 'KOREA_NORTH',
  KOREA_SOUTH = 'KOREA_SOUTH',
  KOSOVO = 'KOSOVO',
  KUWAIT = 'KUWAIT',
  KYRGYZSTAN = 'KYRGYZSTAN',
  LAOS = 'LAOS',
  LATVIA = 'LATVIA',
  LEBANON = 'LEBANON',
  LESOTHO = 'LESOTHO',
  LIBERIA = 'LIBERIA',
  LIBYA = 'LIBYA',
  LITHUANIA = 'LITHUANIA',
  LUXEMBOURG = 'LUXEMBOURG',
  MACEDONIA = 'MACEDONIA',
  MADAGASCAR = 'MADAGASCAR',
  MALAWI = 'MALAWI',
  MALAYSIA = 'MALAYSIA',
  MALDIVES = 'MALDIVES',
  MALI = 'MALI',
  MALTA = 'MALTA',
  MARSHALL_ISLANDS = 'MARSHALL_ISLANDS',
  MAURITANIA = 'MAURITANIA',
  MAURITIUS = 'MAURITIUS',
  MEXICO = 'MEXICO',
  MICRONESIA = 'MICRONESIA',
  MOLDOVA = 'MOLDOVA',
  MONACO = 'MONACO',
  MONGOLIA = 'MONGOLIA',
  MONTENEGRO = 'MONTENEGRO',
  MOROCCO = 'MOROCCO',
  MOZAMBIQUE = 'MOZAMBIQUE',
  MYANMAR_BURMA = 'MYANMAR_BURMA',
  NAMIBIA = 'NAMIBIA',
  NAURU = 'NAURU',
  NEPAL = 'NEPAL',
  NETHERLANDS = 'NETHERLANDS',
  NEW_ZEALAND = 'NEW_ZEALAND',
  NICARAGUA = 'NICARAGUA',
  NIGER = 'NIGER',
  NIGERIA = 'NIGERIA',
  NORWAY = 'NORWAY',
  OMAN = 'OMAN',
  PAKISTAN = 'PAKISTAN',
  PALAU = 'PALAU',
  PANAMA = 'PANAMA',
  PAPUA_NEW_GUINEA = 'PAPUA_NEW_GUINEA',
  PARAGUAY = 'PARAGUAY',
  PERU = 'PERU',
  PHILIPPINES = 'PHILIPPINES',
  POLAND = 'POLAND',
  PORTUGAL = 'PORTUGAL',
  QATAR = 'QATAR',
  ROMANIA = 'ROMANIA',
  RUSSIAN_FEDERATION = 'RUSSIAN_FEDERATION',
  RWANDA = 'RWANDA',
  ST_KITTS_AND_NEVIS = 'ST_KITTS_AND_NEVIS',
  ST_LUCIA = 'ST_LUCIA',
  SAINT_VINCENT_AND_THE_GRENADINES = 'SAINT_VINCENT_AND_THE_GRENADINES',
  SAMOA = 'SAMOA',
  SAN_MARINO = 'SAN_MARINO',
  SAO_TOME_AND_PRINCIPE = 'SAO_TOME_AND_PRINCIPE',
  SAUDI_ARABIA = 'SAUDI_ARABIA',
  SENEGAL = 'SENEGAL',
  SERBIA = 'SERBIA',
  SEYCHELLES = 'SEYCHELLES',
  SIERRA_LEONE = 'SIERRA_LEONE',
  SINGAPORE = 'SINGAPORE',
  SLOVAKIA = 'SLOVAKIA',
  SLOVENIA = 'SLOVENIA',
  SOLOMON_ISLANDS = 'SOLOMON_ISLANDS',
  SOMALIA = 'SOMALIA',
  SOUTH_AFRICA = 'SOUTH_AFRICA',
  SOUTH_SUDAN = 'SOUTH_SUDAN',
  SPAIN = 'SPAIN',
  SRI_LANKA = 'SRI_LANKA',
  SUDAN = 'SUDAN',
  SURINAME = 'SURINAME',
  SWAZILAND = 'SWAZILAND',
  SWEDEN = 'SWEDEN',
  SYRIA = 'SYRIA',
  TAIWAN = 'TAIWAN',
  TAJIKISTAN = 'TAJIKISTAN',
  TANZANIA = 'TANZANIA',
  THAILAND = 'THAILAND',
  TOGO = 'TOGO',
  TONGA = 'TONGA',
  TRINIDAD_AND_TOBAGO = 'TRINIDAD_AND_TOBAGO',
  TUNISIA = 'TUNISIA',
  TURKEY = 'TURKEY',
  TURKMENISTAN = 'TURKMENISTAN',
  TUVALU = 'TUVALU',
  UGANDA = 'UGANDA',
  UKRAINE = 'UKRAINE',
  UNITED_ARAB_EMIRATES = 'UNITED_ARAB_EMIRATES',
  UNITED_KINGDOM = 'UNITED_KINGDOM',
  UNITED_STATES = 'UNITED_STATES',
  URUGUAY = 'URUGUAY',
  UZBEKISTAN = 'UZBEKISTAN',
  VANUATU = 'VANUATU',
  VATICAN_CITY = 'VATICAN_CITY',
  VENEZUELA = 'VENEZUELA',
  VIETNAM = 'VIETNAM',
  YEMEN = 'YEMEN',
  ZAMBIA = 'ZAMBIA',
  ZIMBABWE = 'ZIMBABWE'
}

export enum QuestionnaireFormActionType {
  REPLACE_STATE = 'REPLACE_STATE',
  SET_PROJECT_ID = 'SET_PROJECT_ID',
  CHANGE_MARITAL_STATUS = 'CHANGE_MARITAL_STATUS',
  CHANGE_GENDER = 'CHANGE_GENDER',
  CHANGE_BIRTH_DATE = 'CHANGE_BIRTH_DATE',
  CHANGE_BIRTH_PLACE = 'CHANGE_BIRTH_PLACE',
  CHANGE_NATIONALITY = 'CHANGE_NATIONALITY',
  CHANGE_PERMIT_TYPE = 'CHANGE_PERMIT_TYPE',
  ADD_ADDRESS = 'ADD_ADDRESS',
  CHANGE_ADDRESS = 'CHANGE_ADDRESS',
  CHANGE_CANTON_AND_CITY = 'CHANGE_CANTON_AND_CITY',
  CHANGE_PHONE_NUMBER = 'CHANGE_PHONE_NUMBER',
  CHANGE_BANK_DETAILS = 'CHANGE_BANK_DETAILS',
  CHANGE_BENEFICIAL_OWNER = 'CHANGE_BENEFICIAL_OWNER',
  CHANGE_RESIDING_SINCE_YEAR = 'CHANGE_RESIDING_SINCE_YEAR',
  CHANGE_HOUSEHOLD_MONTHLY_INSURANCE_FEE = 'CHANGE_HOUSEHOLD_MONTHLY_INSURANCE_FEE',
  CHANGE_RESIDING_SINCE_MONTH = 'CHANGE_RESIDING_SINCE_MONTH',
  CHANGE_FINANCIAL_DETAILS = 'CHANGE_FINANCIAL_DETAILS',
  CHANGE_MONTHLY_INCOME_ADDITONAL = 'CHANGE_MONTHLY_INCOME_ADDITONAL',
  CHANGE_EMPLOYMENT_STATUS = 'CHANGE_EMPLOYMENT_STATUS',
  CHANGE_HOUSEHOLD_TYPE = 'CHANGE_HOUSEHOLD_TYPE',
  CHANGE_RESIDENCE_TYPE = 'CHANGE_RESIDENCE_TYPE',
  CHANGE_HOUSEHOLD_MEMBERS = 'CHANGE_HOUSEHOLD_MEMBERS',
  REMOVE_HOUSEHOLD_MEMBERS = 'REMOVE_HOUSEHOLD_MEMBERS',
  CHANGE_CHILD_AGE = 'CHANGE_CHILD_AGE',
  CHANGE_TRANSPORT_TYPE = 'CHANGE_TRANSPORT_TYPE',
  CHANGE_CHURCH_TAX_OBLIGATION = 'CHANGE_CHURCH_TAX_OBLIGATION',
  CHANGE_MONTHLY_INCOME_ADDITIONAL = 'CHANGE_MONTHLY_INCOME_ADDITIONAL',
  CHANGE_PARTNER_BIRTH_DATE = 'CHANGE_PARTNER_BIRTH_DATE',
  CHANGE_PARTNER_MARITAL_STATUS = 'CHANGE_PARTNER_MARITAL_STATUS',
  CHANGE_PARTNER_ADDRESS = 'CHANGE_PARTNER_ADDRESS',
  SET_PARTNER_ADDRESS_ASS_APPLICANT = 'SET_PARTNER_ADDRESS_ASS_APPLICANT',
  CHANGE_PARTNER_CITY_AND_CANTON = 'CHANGE_PARTNER_CITY_AND_CANTON',
  CHANGE_PARTNER_EMPLOYMENT_STATUS = 'CHANGE_PARTNER_EMPLOYMENT_STATUS',
  CHANGE_PARTNER_TRANSPORT_TYPE = 'CHANGE_PARTNER_TRANSPORT_TYPE',
  CHANGE_PARTNER_CHURCH_TAX_OBLIGATION = 'CHANGE_PARTNER_CHURCH_TAX_OBLIGATION',
  CHANGE_PARTNER_FINANCIAL_DETAILS = 'CHANGE_PARTNER_FINANCIAL_DETAILS',
  CHANGE_PARTNER_MONTHLY_INCOME_ADDITONAL = 'CHANGE_PARTNER_MONTHLY_INCOME_ADDITONAL',
  REMOVE_PARTNER_MONTHLY_TRANSPORTATION_COSTS = 'REMOVE_PARTNER_MONTHLY_TRANSPORTATION_COSTS'
}
