import { ContractGet, ContractStatus, ContractType } from '../../generated';

// TODO AP-165 : REMOVE mockContract when backend is set up
export const mockContract: ContractGet = {
  id: '123456',
  type: ContractType.Loan,
  expirationDate: '2025-01-01T09:00:00Z',
  status: ContractStatus.ContractValid,
  documents: [
    {
      fileName: 'PN003021_Kreditvertrag.pdf',
      filePath: 'https://example.com/documents/PN003021_Kreditvertrag.pdf'
    },
    {
      fileName: 'PN003021_Anhang.pdf',
      filePath: 'https://example.com/documents/PN003021_Anhang.pdf'
    },
    {
      fileName: 'PN003020_Anhang.pdf',
      filePath: 'https://example.com/documents/PN003020_Anhang.pdf'
    },
    {
      fileName: 'PN003020_Kreditvertrag.pdf',
      filePath: 'https://example.com/documents/PN003020_Kreditvertrag.pdf'
    }
  ],
  comment: 'This contract was finalized for a loan agreement.',
  events: [
    {
      date: '2025-01-01T09:00:00Z',
      description: 'Contract created and sent to the customer.'
    },
    {
      date: '2025-01-05T11:00:00Z',
      description: 'Customer signed the contract.'
    }
  ]
};
