import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useNavigation from '../../../../hooks/useNavigation';

import Text from '../../../../components/Text/Text';
import Button from '../../../../components/Button/Button';
import ContractItem from '../../../../components/ContractItem/ContractItem';

import { mockContract } from '../../../../store/contracts/contract.types';

import { getAllContracts } from '../../../../store/contracts/contract.actions';
import { ContractGet } from '../../../../generated';

import addIcon from '../../../../assets/Icons/add.svg';

import './Contracts.scss';

const Contracts = () => {
  const { t } = useTranslation();
  const { goTo } = useNavigation();

  const [contracts, setContracts] = useState<ContractGet[]>([mockContract]);

  useEffect(() => {
    fetchContracts();
  }, []);

  const fetchContracts = async () => {
    const res = await getAllContracts();
    !!res.length && setContracts(res);
  };

  const addContractButtonHandler = () => {
    goTo('/admin/contract');
  };

  return (
    <div className="contracts-wrapper">
      <Text as="h3" category="headline" size="large">
        {t(`admin.projectDetails.contracts.title`)}
      </Text>
      <Text>{t('admin.projectDetails.contracts.noContracts')}</Text>
      <div className="contract-actions">
        <Button
          variation="primary"
          size="large"
          dataTest=""
          icon={addIcon}
          iconPosition="right"
          onClick={addContractButtonHandler}>
          {t('admin.projectDetails.contracts.addContract')}
        </Button>
        <Button
          variation="secondary"
          size="large"
          dataTest=""
          icon={addIcon}
          iconPosition="right"
          onClick={addContractButtonHandler}>
          {t('admin.projectDetails.contracts.withdrawFinancing')}
        </Button>
      </div>
      {!!contracts.length &&
        contracts.map((c) => <ContractItem key={c.id} id={c.id} status={c.status} />)}
    </div>
  );
};

export default Contracts;
