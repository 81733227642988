import { useTranslation } from 'react-i18next';

import Text from '../../../../../components/Text/Text';
import TextInput from '../../../../../components/Inputs/TextInput/TextInput';

import { PartnerCompany } from '../../../../../store/partners/partners.types';
import { SetStateType } from '../../../../../types/util.types';
import { ContactDetailsType } from '../CompanyData';

import '../CompanyData.scss';

interface CompanyDataFormProps {
  companyData: PartnerCompany;
  contactDetails: ContactDetailsType;
  setContactDetails: SetStateType<PartnerCompany>;
}

const CompanyDataForm = ({
  companyData,
  contactDetails,
  setContactDetails
}: CompanyDataFormProps) => {
  const { t } = useTranslation();

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setContactDetails((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <div>
      <Text as="h2" category="headline" size="large">
        {t('partners.account.companyData.title')}
      </Text>
      <div className="company-data-form">
        <TextInput
          value={companyData?.name || ''}
          name="companyName"
          type="text"
          disabled
          placeholder={t('companyName')}
        />
        <TextInput
          value={companyData?.legalForm || ''}
          name="legalForm"
          type="text"
          disabled
          placeholder={t('partners.legalForm')}
        />
        <div className="company-address">
          <TextInput
            value={companyData.partnerLocations ? companyData?.partnerLocations[0]?.address : ''}
            name="address"
            type="text"
            disabled
            placeholder={t('streetAndHouseNumber')}
          />
          <TextInput
            value={companyData.partnerLocations ? companyData?.partnerLocations[0]?.postalCode : ''}
            name="postalCode"
            type="text"
            disabled
            placeholder={t('zipCode')}
          />
          <TextInput
            value={
              companyData.partnerLocations ? companyData?.partnerLocations[0]?.municipality : ''
            }
            name="location"
            type="text"
            disabled
            placeholder={t('location')}
          />
        </div>
        <TextInput
          value={companyData.uid || ''}
          name="vatNumber"
          type="text"
          disabled
          placeholder={t('partners.vatNumber')}
        />
      </div>
      <Text as="h2" category="headline">
        {t('partners.account.companyData.userData')}
      </Text>
      <div className="company-data-form">
        <TextInput
          value={contactDetails.firstName}
          onChange={handleNameChange}
          name="contactFirstName"
          type="text"
          placeholder={t('name')}
        />
        <TextInput
          value={contactDetails?.lastName}
          onChange={handleNameChange}
          name="contactLastName"
          type="text"
          placeholder={t('lastName')}
        />
        <TextInput
          value={contactDetails.email || ' '}
          name="contactEmail"
          type="email"
          placeholder={t('email')}
          disabled
        />
      </div>
    </div>
  );
};

export default CompanyDataForm;
