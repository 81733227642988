import { useTranslation } from 'react-i18next';
import { ContractDocument } from '../../../generated';
import { getContractDocument } from '../../../store/contracts/contract.actions';
import Text from '../../Text/Text';
import ContractDocumentItem from './ContractDocumentItem/ContractDocumentItem';

interface ContractDocumentsListProps {
  documents?: ContractDocument[];
  contractId?: string;
}

const ContractDocumentsList = ({ documents, contractId }: ContractDocumentsListProps) => {
  const { t } = useTranslation();

  const fetchContractDocument = async (path?: string) => {
    if (contractId && path) {
      const res = await getContractDocument(contractId, path);

      return res;
    }
    return null;
  };

  return (
    <>
      <Text>{t('admin.projectDetails.contracts.contractDocuments')}:</Text>
      <div className="contract-documents">
        {documents?.map((d, i) => (
          <ContractDocumentItem
            key={i}
            fileName={d.fileName}
            onClick={() => fetchContractDocument(d.filePath)}
          />
        ))}
      </div>
    </>
  );
};

export default ContractDocumentsList;
