import { useEffect, useState } from 'react';
import { GoogleMap, MarkerF, useJsApiLoader } from '@react-google-maps/api';
import { Coordinates } from '../../types/sepMap.types';

interface MapProps {
  hideControls: boolean;
  className?: string;
  initialCordinates: Coordinates;
}

const Map = ({ hideControls, className, initialCordinates }: MapProps) => {
  const [maps, setMaps] = useState(null);
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ''
  });

  const [coordinates, setCoordinates] = useState<google.maps.LatLng>();

  useEffect(() => {
    initialCordinates && maps && setCoordinatesFromAddreses(initialCordinates);
  }, [maps, initialCordinates]);

  const hiddenControls = hideControls && {
    disableDefaultUI: true
  };

  const setCoordinatesFromAddreses = async (cordinates: Coordinates) => {
    if (maps !== null) {
      const googleCordinates = new google.maps.LatLng(cordinates);
      cordinates && setCoordinates(googleCordinates);
    }
  };

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  const handleMapLoad = (map: any) => {
    setMaps(map);
  };

  return (
    <>
      {isLoaded && (
        <GoogleMap
          onLoad={handleMapLoad}
          mapContainerClassName={className}
          center={coordinates}
          options={{
            mapTypeId: 'satellite',
            zoom: 18,
            ...hiddenControls
          }}
          tilt={0}>
          <MarkerF visible={false} position={initialCordinates} />
        </GoogleMap>
      )}
    </>
  );
};

export default Map;
