import { createBrowserRouter, createRoutesFromElements, Route, Outlet } from 'react-router-dom';
import { ProtectedRoute } from './components/ProtectedRoute/ProtectedRoute';
import KycIdentification from './views/KycIdentification/KycIdentification';
import NotFound from './views/NotFound/NotFound';
import Offers from './views/Offers/Offers';
import Inbox from './views/Profile/Inbox/Inbox';
import PersonalData from './views/Profile/PersonalData/PersonalData';
import Profile from './views/Profile/Profile';
import ProjectOverview from './views/Projects/ProjectOverview/ProjectOverview';
import Projects from './views/Projects/Projects';
import Questionnaire from './views/Questionnaire/Questionnaire';
import SolarSystem from './views/SolarSystem/SolarSystem';
import UploadOffer from './views/UploadOffer/UploadOffer';
import UploadOfferEdit from './views/UploadOffer/UploadOfferEdit';
import ClientDetails from './views/admin/Clients/ClientDetails/ClientDetails';
import Clients from './views/admin/Clients/Clients';
import Dashboard from './views/admin/Dashboard/Dashboard';
import GlobalVariables from './views/admin/GlobalVariables/GlobalVariables';
import PartnerDetails from './views/admin/PartnerDetails/PartnerDetails';
import PartnersOverview from './views/admin/PartnersOverview/PartnersOverview';
import Project from './views/admin/Project/Project';
import ProjectsOverview from './views/admin/ProjectsOverivew/ProjectsOverview';
import PartnerOfferView from './views/partners/Offer/PartnerOfferView';
import PartnerAccount from './views/partners/PartnerAccount/PartnerAccount';
import PartnerDashboard from './views/partners/PartnerDashboard/PartnerDashboard';
import PartnerProjectSummary from './views/partners/PartnerProjectSummary/PartnerProjectSummary';
import PartnerProjectsOverview from './views/partners/PartnerProjectsOverview/PartnerProjectsOverview';
import Registration from './views/partners/Registration/Registration';
import BasicPageLayout from './components/BasicPageLayout/BasicPageLayout';
import KycResult from './views/KycResult/KycResult';
import { SHOW_QUESTIONNAIRE, SHOW_NEW_UPLOAD_OFFER, SHOW_CONTRACTS } from './utils/general.utils';
import InstallationOffer from './views/InstallationOffer/InstallationOffer';
import ContractDetails from './components/ContractDetails/ContractDetails';
import Contract from './views/admin/Project/Contracts/Contract/Contract';
import StartFinancing from './views/StartFinancing/StartFinancing';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<BasicPageLayout />}>
      <Route path=":language/admin" element={<ProtectedRoute />}>
        <Route index element={<Dashboard />} />
        <Route path="projects" element={<Outlet />}>
          <Route index element={<ProjectsOverview />} />
          <Route path=":projectUuid" element={<Project />} />
        </Route>
        <Route path="platform-terms" element={<GlobalVariables />} />
        <Route path="clients" element={<Clients />} />
        {/* <Route path="invoices" element={<AllInvoices />} /> */}
        <Route path="personal-data" element={<PersonalData />} />
        <Route path="partners" element={<PartnersOverview />} />
        <Route path="partners/:partnerId" element={<PartnerDetails />} />
        <Route path="project/:projectId/offer" element={<Offers />} />
        <Route path="client/:clientId" element={<ClientDetails />} />
        {SHOW_CONTRACTS && (
          <Route path="contract" element={<Outlet />}>
            <Route index element={<Contract />} />
            <Route path=":contractId" element={<ContractDetails />} />
          </Route>
        )}
      </Route>
      <Route path=":language/my-profile" element={<ProtectedRoute />}>
        <Route index element={<Profile />} />
        <Route path="personal-data" element={<PersonalData />} />
        <Route path="inbox" element={<Inbox />} />
        <Route path="projects" element={<Outlet />}>
          <Route index element={<Projects />} />
          <Route path=":projectUuid" element={<ProjectOverview />} />
        </Route>
      </Route>
      <Route
        path=":language/project/:projectId/offer"
        element={
          <ProtectedRoute>
            <Offers />
          </ProtectedRoute>
        }
      />
      {SHOW_QUESTIONNAIRE && (
        <Route
          path=":language/kyc-start/:projectUuid"
          element={
            <ProtectedRoute>
              <KycIdentification />
            </ProtectedRoute>
          }
        />
      )}
      {SHOW_QUESTIONNAIRE && (
        <Route path=":language/kyc/result/:kycResult" element={<KycResult />} />
      )}
      {SHOW_QUESTIONNAIRE && (
        <Route
          path=":language/questionnaire/:projectId"
          element={
            <ProtectedRoute>
              <Questionnaire />
            </ProtectedRoute>
          }
        />
      )}
      <Route
        path=":language/start-financing/:projectUuid"
        element={
          <ProtectedRoute>
            <StartFinancing />
          </ProtectedRoute>
        }
      />
      <Route path=":language/partners" element={<ProtectedRoute />}>
        <Route index element={<PartnerDashboard />} />
        <Route path="register" element={<Registration />} />
        <Route path="offers" element={<PartnerProjectsOverview />} />
        {/* <Route path="finance" element={<PartnerFinance />} /> */}
        <Route path="offer/:offerId" element={<PartnerOfferView />} />
        <Route path="project/:projectUuid" element={<PartnerProjectSummary />} />
        <Route path="account" element={<PartnerAccount />} />
      </Route>
      <Route path=":language/upload-offer" element={<Outlet />}>
        <Route index element={SHOW_NEW_UPLOAD_OFFER ? <InstallationOffer /> : <UploadOffer />} />
        {!SHOW_NEW_UPLOAD_OFFER && <Route path="edit" element={<UploadOfferEdit />} />}
      </Route>
      <Route path=":language/solar-system" element={<SolarSystem />} />
      <Route path=":language" element={<ProtectedRoute />} />
      <Route path="*" element={<NotFound />} />
    </Route>
  )
);

export default router;
