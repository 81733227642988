import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import EditInstallationOffer from './EditInstallationOffer/EditInstallationOffer';
import EditInstallationPartner from './EditInstallationPartner/EditInstallationPartner';
import EditPersonalDetails from './EditPersonalDetails/EditPersonalDetails';
import FinancialOfferPreview from '../../components/FinancialOfferPreview/FinancialOfferPreview';
import FinancingPlan from '../../components/FinancingPlan/FinancingPlan';
import Text from '../../components/Text/Text';
import Loader from '../../components/Loader/Loader';

import { AuthContext } from '../../context/AuthContextProvider';
import useDebounce from '../../hooks/useDebounce';
import useNavigation from '../../hooks/useNavigation';

import { InstallationOfferCreate } from '../../generated/api';
import { RootState } from '../../reducers/rootReducer';
import { FinancialOverview, FinancialOverviewType } from '../../store/project/project.types';
import { InstallationOfferActionType } from '../../store/offers/offers.types';
import { User } from '../../store/user/types';

import { handleCreateInstallationOffer } from '../../store/offers/offers.action';
import { getFinancialOverview } from '../../store/project/project.action';

import { hasTruthyValues } from '../../utils/general.utils';
import { toastUtil } from '../../utils/toast.utils';

import './InstallationOffer.scss';

export interface InstallationOfferEditProps {
  isDataMissing: boolean;
}

const InstallationOffer = () => {
  const { t } = useTranslation();
  const { goTo } = useNavigation();
  const auth = useContext(AuthContext);
  const user = useSelector<RootState>((state) => state.userReducer) as User;

  const offerDetails = useSelector<RootState>(
    (state) => state.installationOfferReducer
  ) as InstallationOfferCreate;
  const dispatch = useDispatch();

  const [overview, setOverview] = useState<FinancialOverview>();

  const [isDataMissing, setIsDataMissing] = useState(false);

  const [loading, setLoading] = useState(false);

  const price = offerDetails.offer?.price ?? 0;
  const priceDebounced = useDebounce(price, 500);

  const installationOfferLocalStorage = localStorage.getItem('installationOffer') ?? '';
  const depositValue = +offerDetails.loan?.deposit || 0;
  const durationValue = +offerDetails.loan?.termYears || 0;

  useEffect(() => {
    getOverview();
  }, [priceDebounced]);

  useEffect(() => {
    if (installationOfferLocalStorage) {
      auth?.isAuthenticated && setLoading(true);

      if (user.uid) {
        const offer = JSON.parse(installationOfferLocalStorage);
        installationOfferCreate(offer);
      }
    }

    return () => {
      const location = window.location.href;
      if (
        !location.includes(process.env.REACT_APP_KEYCLOAK_URL?.toString() as string) &&
        !location.includes('upload-offer')
      ) {
        clearLocalStorage();
      }
    };
  }, [user.uid]);

  const getOverview = () => {
    if (offerDetails && price) {
      const inputs = {
        type: FinancialOverviewType.FINAL,
        offerPrice: +price,
        deposit: depositValue,
        duration: durationValue,
        modules: []
      };
      getFinancialOverview(inputs).then((resp) => {
        setOverview(resp);
      });
    }
  };

  const changeDeposit = (newDeposit: number) => {
    dispatch({
      type: InstallationOfferActionType.CHANGE_LOAN_DETAILS,
      payload: { key: 'deposit', value: newDeposit.toString() }
    });
  };

  const changeDuration = (newDuration: number) => {
    dispatch({
      type: InstallationOfferActionType.CHANGE_LOAN_DETAILS,
      payload: { key: 'termYears', value: newDuration }
    });
  };

  const installationOfferCreate = async (installationOffer: InstallationOfferCreate) => {
    !loading && setLoading(true);
    const res = await handleCreateInstallationOffer(installationOffer);
    if (res?.project) {
      clearLocalStorage();
      setLoading(false);
      window.scrollTo(0, 0);
      goTo(`/start-financing/${res.project?.id}`, { state: { hideCrumbs: true } });
    }
  };

  const onSubmit = async () => {
    if (!hasTruthyValues(offerDetails)) {
      setIsDataMissing(true);
      toastUtil(
        'error',
        `${
          !offerDetails.filePath
            ? t('installationOffer.fileRequired')
            : t('installationOffer.allFieldsRequired')
        }`,
        { autoClose: 10000 }
      );
      return;
    }

    if (!auth?.isAuthenticated) {
      localStorage.setItem('installationOffer', JSON.stringify(offerDetails));
      auth?.keycloak.login({ redirectUri: window.location.href });
      return;
    }

    installationOfferCreate(offerDetails);
  };

  const clearLocalStorage = () => {
    localStorage.removeItem('installationOffer');
    localStorage.removeItem('overview');
  };

  return (
    <div className="installation-offer-wrapper">
      <Text as="h3" category="display" size="medium" className="installation-offer-title">
        {t('offerDetails')}
      </Text>
      <div className="installation-offer">
        <div className="offer-details">
          <EditPersonalDetails isDataMissing={isDataMissing} />
          <EditInstallationPartner isDataMissing={isDataMissing} />
          <EditInstallationOffer isDataMissing={isDataMissing} />
        </div>
        <div className="financial-overview">
          <FinancialOfferPreview status="final" overview={overview} />
          <FinancingPlan
            showRate={!!price}
            amount={+price}
            interestRate={overview?.effectiveAnnualInterestRate}
            baseFee={overview?.fee}
            status={'final'}
            showProhibitText
            onSubmit={onSubmit}
            onDepositChange={getOverview}
            deposit={depositValue}
            duration={durationValue}
            setDeposit={changeDeposit}
            setDuration={changeDuration}
            disable={!price}
          />
        </div>
      </div>
      {loading && <Loader />}
    </div>
  );
};

export default InstallationOffer;
