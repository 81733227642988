import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Text from '../../../components/Text/Text';
import TextInput from '../../../components/Inputs/TextInput/TextInput';
import PhoneTextInput from '../../../components/Inputs/PhoneTextInput/PhoneTextInput';
import Search from '../../../components/Search/Search';

import { InstallationOfferCreate } from '../../../generated/api';
import { BuildingAddress } from '../../../types/sepMap.types';
import { RootState } from '../../../reducers/rootReducer';
import { InstallationOfferEditProps } from '../InstallationOffer';
import { InstallationOfferActionType } from '../../../store/offers/offers.types';
import { User } from '../../../store/user/types';

import { getBuildingByAddress, searchMesAddress } from '../../../store/mes/mes.actions';

import { formatAddress } from '../../../utils/formatting.utils';
import { emailValidation, validateEmpty } from '../../../utils/validations.utils';
import { getHighlightClass } from '../../../utils/questionnaire.utils';

import '../InstallationOffer.scss';

const EditPersonalDetails = ({ isDataMissing }: InstallationOfferEditProps) => {
  const { t } = useTranslation();

  const user = useSelector<RootState, User>((state) => state.userReducer);
  const { personalDetails, ...rest } = useSelector<RootState, InstallationOfferCreate>(
    (state) => state.installationOfferReducer
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (user.id) {
      dispatch({
        type: InstallationOfferActionType.REPLACE_STATE,
        payload: {
          ...rest,
          personalDetails: {
            ...personalDetails,
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            ...(user.phoneNumber && { phoneNumber: user.phoneNumber })
          }
        }
      });
    }
  }, [user.id]);

  const getAddresses = async (address: string) => {
    const data = await searchMesAddress(address);
    if (data) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return data.results?.map((elem: any) => {
        const { label, ...attrs } = elem.attrs;
        return { ...attrs, value: label.replace(/<b>/g, '').replace(/<\/b>/g, '') };
      });
    } else {
      return [];
    }
  };

  const handlePersonalDetailsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dispatch({
      type: InstallationOfferActionType.CHANGE_PERSONAL_DETAILS,
      payload: {
        field: name,
        value
      }
    });
  };

  const handlePhoneNumberChange = (value: string) => {
    dispatch({
      type: InstallationOfferActionType.CHANGE_PERSONAL_DETAILS,
      payload: {
        field: 'phoneNumber',
        value
      }
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleAddressChange = async (selectedAddress: any) => {
    const building =
      selectedAddress &&
      (await getBuildingByAddress({ detail: selectedAddress.value, num: selectedAddress.num }));

    if (!building) {
      handleRemoveBuilding();
      return;
    }

    dispatch({
      type: InstallationOfferActionType.CHANGE_BUILDING,
      payload: {
        sepId: building.sepId,
        address: building.address,
        latitude: building.location.coordinates.lat,
        longitude: building.location.coordinates.lng
      }
    });
  };

  const handleRemoveBuilding = () => {
    dispatch({
      type: InstallationOfferActionType.CHANGE_BUILDING,
      payload: undefined
    });
  };

  return (
    <div>
      <Text as="h3" category="headline" size="large">
        {t('installationOffer.personalDetails')}
      </Text>
      <div className="installation-offer-form-section">
        <TextInput
          value={personalDetails?.firstName}
          name="firstName"
          type="text"
          placeholder={t('name')}
          validate={validateEmpty}
          onChange={handlePersonalDetailsChange}
          className={getHighlightClass(personalDetails?.firstName, isDataMissing)}
          disabled={!!user.firstName}
          required
        />
        <TextInput
          value={personalDetails?.lastName}
          name="lastName"
          type="text"
          placeholder={t('lastName')}
          validate={validateEmpty}
          onChange={handlePersonalDetailsChange}
          className={getHighlightClass(personalDetails?.lastName, isDataMissing)}
          disabled={!!user.lastName}
          required
        />
        <Search
          initialValue={formatAddress(personalDetails?.building?.address as BuildingAddress)}
          placeholder={t('uploadOffer.searchHereForAddress')}
          fetchData={getAddresses}
          onOptionSelect={handleAddressChange}
          onClear={handleRemoveBuilding}
          className={getHighlightClass(personalDetails?.building?.address, isDataMissing)}
          required
        />
        <TextInput
          value={personalDetails?.email}
          name="email"
          type="email"
          placeholder={t('email')}
          validate={emailValidation}
          onChange={handlePersonalDetailsChange}
          className={getHighlightClass(personalDetails?.email, isDataMissing)}
          disabled={!!user.email}
          required
        />
        <PhoneTextInput
          value={personalDetails?.phoneNumber}
          onChange={handlePhoneNumberChange}
          className={getHighlightClass(personalDetails?.phoneNumber, isDataMissing)}
        />
      </div>
    </div>
  );
};

export default EditPersonalDetails;
