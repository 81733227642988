import { forwardRef, InputHTMLAttributes } from 'react';
import Text from '../Text/Text';

interface CustomInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  disabled?: boolean;
  validation?: string;
  className?: string;
  required?: boolean;
}

const CustomInput = forwardRef<HTMLInputElement, CustomInputProps>(
  ({ label, disabled, validation, className = '', required, ...props }, ref) => {
    const getClassName = () =>
      `input-container ${disabled ? 'disabled' : ''} ${validation ? 'error' : ''} ${className}`;

    return (
      <div className={getClassName()}>
        <input
          {...props}
          ref={ref}
          type="text"
          className="input"
          placeholder=" "
          required={required}
        />
        <Text as="label" category="label" size="large" className="placeholder">
          {label}
          {required ? '*' : ''}
        </Text>
      </div>
    );
  }
);

CustomInput.displayName = 'CustomInput';

export default CustomInput;
