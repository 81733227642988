import Text from '../../../Text/Text';
import Icon, { icons } from '../../../Icon/Icon';

import './ContractDocumentItem.scss';

interface ContractDocumentItemProps {
  fileName?: string;
  className?: string;
  icon?: keyof typeof icons;
  onClick?: () => void;
}

const ContractDocumentItem = ({
  fileName,
  className = '',
  icon = 'download',
  onClick
}: ContractDocumentItemProps) => {
  return (
    <div className={`contract-document ${className}`} onClick={onClick}>
      <Text as="p">{fileName}</Text>
      <Icon name={icon} />
    </div>
  );
};

export default ContractDocumentItem;
