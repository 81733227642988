import React from 'react';
import { ContractEvent } from '../../../generated';
import ContractEventLogItem from './ContractEventLogItem/ContractEventLogItem';
import './ContractEventsLog.scss';

interface ContractEventsLogProps {
  events?: ContractEvent[];
}

const ContractEventsLog = ({ events }: ContractEventsLogProps) => {
  return (
    <div className="contract-events-log">
      {events?.map((e, i) => (
        <ContractEventLogItem key={i} event={e} />
      ))}
    </div>
  );
};

export default ContractEventsLog;
