import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomDatePicker, { DatePickerProps, registerLocale } from 'react-datepicker';
import { de, frCH } from 'date-fns/locale';
import { ReactSVG } from 'react-svg';

import CustomDatePickerHeader from './CustomDatePickerHeader';
import CustomInput from './CustomInput';
import Text from '../Text/Text';

import calendarIcon from '../../assets/Icons/calendar.svg';

import 'react-datepicker/dist/react-datepicker.css';
import './DatePicker.scss';

type DatePickerCustomProps = DatePickerProps & {
  selected: Date | null;
  label?: string;
  required?: boolean;
  validate?: (value: string | Date) => string;
  fromYears?: number;
  setSelected: (date: Date | null) => void;
} & {
  selectsRange?: never;
  selectsMultiple?: never;
};

registerLocale('fr', frCH);
registerLocale('de', de);

const DatePicker = ({
  selected,
  setSelected,
  label,
  validate,
  required,
  fromYears,
  ...props
}: DatePickerCustomProps) => {
  const { i18n, t } = useTranslation();

  const [error, setError] = useState<string | undefined>();

  const datepickerRef = useRef(null);

  const onChangeHandler = (date: Date | null) => {
    setSelected(date);

    if (validate && date) {
      const errorMessage = validate(date);
      setError(errorMessage);
      return;
    }
    setError(undefined);
  };

  const generateDayClassName = (date: Date) => {
    if (selected && date.toDateString() === selected.toDateString()) return 'selected-day';
    return 'day-class';
  };

  return (
    <div>
      <CustomDatePicker
        locale={i18n.language}
        dateFormat="dd.MM.yyyy"
        calendarClassName="calendar"
        wrapperClassName="date-input-wrapper"
        calendarIconClassName="calendar-icon"
        dayClassName={generateDayClassName}
        icon={<ReactSVG src={calendarIcon} />}
        showIcon={!selected}
        toggleCalendarOnIconClick
        selected={selected}
        isClearable
        clearButtonClassName="clear-button"
        onSelect={onChangeHandler}
        onChange={onChangeHandler}
        ref={datepickerRef}
        customInput={<CustomInput label={label || t('selectDate')} />}
        renderCustomHeader={(props) => <CustomDatePickerHeader {...props} fromYears={fromYears} />}
        maxDate={new Date()}
        showPopperArrow={false}
        required={required}
        {...props}
      />
      {error && (
        <Text as="p" category="body" size="small" className="error-text">
          {t(error)}
        </Text>
      )}
    </div>
  );
};

export default DatePicker;
