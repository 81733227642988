import { useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Text from '../../../components/Text/Text';
import './UploadFile.scss';
import Icon from '../../../components/Icon/Icon';
import Button from '../../../components/Button/Button';

interface UploadFileProps {
  style?: React.CSSProperties;
  disable?: boolean;
  acceptFileType?: string;
  variation?: 'simple' | 'detailed';
  handleChangeFiles: (payload: FileList | null) => void;
}

const UploadFile = ({
  style = {},
  disable = false,
  acceptFileType,
  variation = 'detailed',
  handleChangeFiles
}: UploadFileProps) => {
  const { t } = useTranslation();
  const fileInput = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    fileInput.current && fileInput.current.click();
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    handleChangeFiles(e.dataTransfer.files);
  };

  const handleFileSelect = () => {
    if (fileInput.current?.files) {
      handleChangeFiles(fileInput.current.files);
      fileInput.current.value = '';
    }
  };

  return (
    <div
      className={`upload-file-wrapper ${disable ? 'disabled' : ''}`}
      style={style}
      onClick={handleClick}
      onDragOver={handleDragOver}
      onDrop={handleDrop}>
      <div className={`uploadFile ${variation}`} data-test="upload_offer-open_file-button">
        <input
          type="file"
          ref={fileInput}
          onChange={handleFileSelect}
          accept={acceptFileType ? `.${acceptFileType}` : undefined}
        />
        {variation === 'detailed' ? (
          <>
            <Icon name={disable ? 'upload-disabled' : 'upload'} height={128} width={128} />
            <div className="upload-file-text">
              <Text as="p" category="body" size="medium" style={{ marginTop: '19px' }}>
                {t('uploadOffer.dragFileHere')}
              </Text>
              <Button variation="primary" size="medium" dataTest="" disabled={disable}>
                <Text as="p" category="label" size="large" className="extensions">
                  {t('searchFile')}
                </Text>
              </Button>
            </div>
          </>
        ) : (
          <Text as="p">
            <Trans
              i18nKey="dragFiles"
              t={t}
              components={{
                colored: <span className="colored-text"></span>
              }}
            />
          </Text>
        )}
      </div>
    </div>
  );
};

export default UploadFile;
