import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Text from '../Text/Text';
import ContractStatusField from '../ContractStatusField/ContractStatusField';
import ContractEventsLog from './ContractEventsLog/ContractEventsLog';
import ContractDocumentsList from './ContractDocumentsList/ContractDocumentsList';

import { ContractGet } from '../../generated';
import { RootState } from '../../reducers/rootReducer';
import { getContract } from '../../store/contracts/contract.actions';
import { mockContract } from '../../store/contracts/contract.types';
import { User } from '../../store/user/types';
import { isBackOfficeUser } from '../../utils/general.utils';

import './ContractDetails.scss';

const ContractDetails = () => {
  const { t } = useTranslation();
  const { contractId } = useParams();

  const user = useSelector<RootState, User>((state) => state.userReducer);

  const [contract, setContract] = useState<ContractGet>(mockContract);

  useEffect(() => {
    fetchContract();
  }, [contractId]);

  const fetchContract = async () => {
    if (contractId) {
      const res = await getContract(contractId);
      res && setContract(res);
    }
  };

  return (
    <div className="contract-details-wrapper">
      <Text as="h2" category="display">
        {t('admin.projectDetails.contracts.LOAN')}
      </Text>
      <div className="contract-details">
        <div className="contract-details-header">
          <Text as="h3" category="headline" size="large">
            {t(`admin.projectDetails.contracts.${contract.type}`)}
          </Text>
          <ContractStatusField />
        </div>
        <div className="contract-details-main">
          <Text>{t('admin.projectDetails.contracts.contractType')}:</Text>
          <Text>{t(`admin.projectDetails.contracts.${contract.type}`)}</Text>

          <ContractDocumentsList documents={contract.documents} contractId={contract.id} />
          {isBackOfficeUser(user) && (
            <>
              <Text>{t('admin.projectDetails.contracts.internalComment')}:</Text>
              <Text dataTestId="admin-contract-comment">{contract.comment}</Text>
            </>
          )}
        </div>
        <ContractEventsLog events={contract.events} />
      </div>
    </div>
  );
};

export default ContractDetails;
