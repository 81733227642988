import { useTranslation } from 'react-i18next';
import Icon from '../Icon/Icon';
import Text from '../Text/Text';
import { KycStatus } from '../../store/profile/profile.types';
import { UserRoles } from '../../store/user/types';
import './KycStatusCard.scss';

interface KycStatusProps {
  role: UserRoles[];
  status?: KycStatus;
}

const KycStatusCard = ({ status, role }: KycStatusProps) => {
  const { t } = useTranslation();

  let statusDisplayValue;

  switch (status) {
    case KycStatus.ADMONISHED ||
      KycStatus.AML_FINAL ||
      KycStatus.SECOND_CHECK_FAIL ||
      KycStatus.KYC_FAIL:
      statusDisplayValue = 'unsuccessful';
      break;
    case KycStatus.KYC_SUCCESS:
      statusDisplayValue = 'successful';
      break;
    default:
      statusDisplayValue = 'pending';
      break;
  }

  return (
    <div className="kyc-wrapper">
      <Icon name="id" className="id-icon" />
      <div className="kyc-info">
        <Text as="h1" category="headline">
          {t('myProfile.personalData.kyc.identification')}
        </Text>
        <span className="kyc-status">
          <Text as="span" category="label" size="medium">
            {t('myProfile.personalData.kyc.status.title')}:
          </Text>
          <Text as="span" category="label" size="medium" className={`kyc-${statusDisplayValue}`}>
            {!role?.includes(UserRoles.ADMIN)
              ? t(`myProfile.personalData.kyc.status.${statusDisplayValue}`)
              : t(`clientStatuses.${status}`)}
          </Text>
        </span>
        <Text as="p">{t('myProfile.personalData.kyc.description')}</Text>
      </div>
    </div>
  );
};

export default KycStatusCard;
