import { isAtLeast18 } from './general.utils';

const isNotEmpty = (value?: string | number) => {
  let result = value !== undefined || value !== null;
  if (typeof value === 'string') {
    result = result && value !== '';
  }
  if (typeof value === 'number') {
    result = !isNaN(value);
  }
  return result;
};

export const validateEmpty = (value?: string | number) => {
  return !isNotEmpty(value) ? 'genericErrors.notEmpty' : '';
};

export const validatePositiveNumber = (value?: string | number) => {
  if (!isNotEmpty(value)) {
    return 'genericErrors.notEmpty';
  }

  if (typeof value === 'string') {
    return isNaN(parseFloat(value)) || parseFloat(value) < 0 ? 'genericErrors.positiveNumber' : '';
  } else {
    return (value || value === 0) && value < 0 ? 'genericErrors.positiveNumber' : '';
  }
};

export const nameValidation = (value: string) => {
  const rgx = /^[\p{L}\s-]+$/u;

  if (!isNotEmpty(value)) {
    return 'genericErrors.notEmpty';
  }

  return !value?.match(rgx) ? 'auth.errors.nameCanContainOnlyLetters' : '';
};

export const emailValidation = (value?: string | number) => {
  const rgx = /^\w+([.-]?\w+)*(\+\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

  if (!isNotEmpty(value)) {
    return 'genericErrors.notEmpty';
  }

  return !value?.toString().trim().match(rgx) ? 'auth.errors.pleaseEnterValidEmail' : '';
};

export const passwordValidation = (value?: string | number): string => {
  const rgx = /^(?=.*[A-Z])(?=.*[^A-Za-z0-9])[A-Za-z\d\S]{8,}$/;

  if (!isNotEmpty(value)) {
    return 'genericErrors.notEmpty';
  }

  return !value?.toString()?.match(rgx) ? 'auth.errors.passwordValidationError' : '';
};

export const confirmPasswordValidation = (
  password?: string | number,
  confirmPassword?: string | number
): string => {
  return password !== confirmPassword ? 'auth.errors.confirmPasswordError' : '';
};

export const phoneNumberValidation = (value?: string | number) => {
  const rgx = /^\+?[1-9][0-9]{7,14}$/;

  if (!isNotEmpty(value)) {
    return 'genericErrors.notEmpty';
  }

  return !value?.toString().match(rgx) ? 'auth.errors.invalidPhoneNumber' : '';
};

export const decimalNumberValidation = (input: string) => {
  const regex = /^[0-9]+(\.[0-9]*)?$/;
  return regex.test(input);
};

export const validatePositiveNumberAllowEmpty = (value?: string | number) => {
  if (typeof value === 'string') {
    return isNaN(parseFloat(value)) || parseFloat(value) < 0 ? 'genericErrors.positiveNumber' : '';
  } else {
    return (value || value === 0) && value < 0 ? 'genericErrors.positiveNumber' : '';
  }
};

export const validateIban = (value?: string) => {
  const rgx = /^(CH|LI|DE|GB|FR|ES|IT)[A-Z0-9]{0,38}$/;

  const cleanedIban = value?.replace(/\s+/g, '');

  return !cleanedIban?.match(rgx) ? 'genericErrors.enterValidIBAN' : '';
};

export const validateAtLeast18YearsOld = (value: string | Date) => {
  return !isAtLeast18(new Date(value)) ? 'genericErrors.mustBe18YearsOld' : '';
};
