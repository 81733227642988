import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TabContent from '../../../components/Tabs/TabContent/TabContent';
import Tabs from '../../../components/Tabs/Tabs';
import Text from '../../../components/Text/Text';
import { Profile, UpdateProfile } from '../../../store/profile/profile.types';
import { getProfile, updateProfile } from '../../../store/profile/profile.actions';
import PersonalDataForm from '../../../components/PersonalDataForm/PersonalDataForm';
import SecurityInfo from './SecurityInfo/SecurityInfo';
import { AuthContext } from '../../../context/AuthContextProvider';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../reducers/rootReducer';
import { User } from '../../../store/user/types';
import { useSearchParams } from 'react-router-dom';
import { updateUser } from '../../../store/user/actions';
import Loader from '../../../components/Loader/Loader';
import { DeepPartial } from '../../../types/util.types';
import './PersonalData.scss';

interface TabsRenderType {
  key: string;
  comp: JSX.Element;
}

const PersonalData = () => {
  const { t } = useTranslation();

  const auth = useContext(AuthContext);
  const user = useSelector<RootState>((state) => state.userReducer) as User;
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();

  const [loading, setLoading] = useState(false);
  const [personalData, setPersonalData] = useState<DeepPartial<Profile>>({});

  const showChangePassword = true;
  //TODO STR1-231: Find a way to read if the IDP was used for reg:  auth?.keycloak?.userInfo && auth.keycloak.userInfo.some((e) => e.providerId === 'password');

  useEffect(() => {
    getPersonalData();
  }, [auth?.isAuthenticated]);

  const getPersonalData = async () => {
    setLoading(true);
    const data = await getProfile();
    data && setPersonalData(data);
    setLoading(false);
  };

  const handlePersonalDataUpdate = async (data: Partial<UpdateProfile>) => {
    setLoading(true);
    const resp = await updateProfile(data, user.uid);
    resp && dispatch(updateUser(data));
    setLoading(false);
  };

  // const handleBankDetailsUpdate = async (data: Partial<UpdateProfile>) => {
  //   setLoading(true);
  //   const resp = await updateProfile(data, user.uid);
  //   if (resp) {
  //     dispatch(updateUser({ bankName: data.bankDetails?.bankName, iBAN: data.bankDetails?.iBAN }));
  //     await getPersonalData();
  //   }
  //   setLoading(false);
  // };

  const getPreselectedTab = () => {
    const tab = searchParams.get('tab');
    switch (tab) {
      case 'security':
        return 1;
      case 'bank-details':
        return 2;
      default:
        return 0;
    }
  };

  const tabsRenderProps = useMemo(() => {
    const tabs: TabsRenderType[] = [
      {
        key: 'personalData',
        comp: (
          <PersonalDataForm
            data={personalData}
            setData={setPersonalData}
            onSubmit={handlePersonalDataUpdate}
          />
        )
      }
      // {
      //   key: 'bankDetails',
      //   comp: (
      //     <BankDetails
      //       className="personal-data-form"
      //       user={personalData.profile}
      //       updateBankDetails={(data) => {
      //         handleBankDetailsUpdate(data);
      //       }}
      //     />
      //   )
      // }
    ];

    if (showChangePassword)
      tabs.splice(1, 0, {
        key: 'security',
        comp: <SecurityInfo />
      });

    return tabs;
  }, [auth?.isAuthenticated, personalData, user]);

  return loading ? (
    <Loader />
  ) : (
    <>
      <Text as="h1" category="display" className="personal-data-title">
        {t('myProfile.cards.personalData.title')}
      </Text>
      <Tabs preSelectedTabIndex={getPreselectedTab()}>
        {tabsRenderProps.map(({ key, comp }, index) => (
          <TabContent
            title={t(`myProfile.${key}.title`)}
            key={index}
            className="personal-data-wrapper">
            {comp}
          </TabContent>
        ))}
      </Tabs>
    </>
  );
};

export default PersonalData;
