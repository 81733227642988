import { useTranslation } from 'react-i18next';

import Text from '../Text/Text';
import { ContractStatus } from '../../generated';

import './ContractStatusField.scss';

interface ContractStatusFieldProps {
  status?: ContractStatus;
}

const ContractStatusField = ({
  status = ContractStatus.ContractSentToCustomer
}: ContractStatusFieldProps) => {
  const { t } = useTranslation();

  const getClassName = () => {
    switch (status) {
      case ContractStatus.ContractSentToCustomer:
        return '';
      case ContractStatus.ContractSignedRightOfWithdrawalActive:
      case ContractStatus.ContractValid:
        return 'valid';
      default:
        return 'notValid';
    }
  };

  return (
    <div className="contract-status-field-wrapper">
      <span className={`circle ${getClassName()}`} />
      <Text as="span" category="label" size="medium">
        {t(`contractStatus.${status}`)}
      </Text>
    </div>
  );
};

export default ContractStatusField;
