import { ReactComponent as BackIcon } from './../../assets/Icons/arrow-back.svg';
import { ReactComponent as CheckIcon } from './../../assets/Icons/checkmark.svg';
import { ReactComponent as CheckGreyIcon } from './../../assets/Icons/checkmark-grey.svg';
import { ReactComponent as UploadIcon } from './../../assets/Icons/upload.svg';
import { ReactComponent as UploadDisabledIcon } from './../../assets/Icons/upload-disabled.svg';
import { ReactComponent as AlertIcon } from './../../assets/Icons/alert-triangle.svg';
import { ReactComponent as VisibilityOnIcon } from './../../assets/Icons/visibility-on.svg';
import { ReactComponent as VisibilityOfIcon } from './../../assets/Icons/visibility-off.svg';
import { ReactComponent as InfoIcon } from './../../assets/Icons/infoIcon.svg';
import { ReactComponent as CallIcon } from './../../assets/Icons/call.svg';
import { ReactComponent as CopyIcon } from './../../assets/Icons/copy.svg';
import { ReactComponent as FacebookIcon } from './../../assets/Icons/socialIcons/facebook.svg';
import { ReactComponent as InstagramIcon } from './../../assets/Icons/socialIcons/instagram.svg';
import { ReactComponent as LinkedinIcon } from './../../assets/Icons/socialIcons/linekdIn.svg';
import { ReactComponent as GoogleIcon } from './../../assets/Icons/socialIcons/google.svg';
import { ReactComponent as AppleIcon } from './../../assets/Icons/socialIcons/apple.svg';
import { ReactComponent as ChatIcon } from './../../assets/Icons/chat.svg';
import { ReactComponent as EnvelopeIcon } from '../../assets/Icons/envelope.svg';
import { ReactComponent as BurgerMenuIcon } from './../../assets/Icons/burger-menu.svg';
import { ReactComponent as CloseIcon } from './../../assets/Icons/close.svg';
import { ReactComponent as SearchIcon } from './../../assets/Icons/search.svg';
import { ReactComponent as UserIcon } from './../../assets/Icons/user.svg';
import { ReactComponent as FolderIcon } from './../../assets/Icons/folder.svg';
import { ReactComponent as SupportIcon } from './../../assets/Icons/support.svg';
import { ReactComponent as FileIcon } from './../../assets/Icons/file.svg';
import { ReactComponent as ZipFileIcon } from './../../assets/Icons/zip-file.svg';
import { ReactComponent as HouseIcon } from './../../assets/Icons/house.svg';
import { ReactComponent as ElipsisVerticalIcon } from './../../assets/Icons/elipsis-vertical.svg';
import { ReactComponent as IdIcon } from './../../assets/Icons/ID.svg';
import { ReactComponent as PersonIcon } from './../../assets/Icons/person.svg';
import { ReactComponent as CalculatorIcon } from './../../assets/Icons/calculator.svg';
import { ReactComponent as FinanceHandIcon } from './../../assets/Icons/finance-hand.svg';
import { ReactComponent as ArrowUpIcon } from './../../assets/Icons/arrow-up.svg';
import { ReactComponent as CommentIcon } from './../../assets/Icons/comment.svg';
import { ReactComponent as EmailIcon } from './../../assets/Icons/email.svg';
import { ReactComponent as UploadWhiteIcon } from './../../assets/Icons/upload-white.svg';
import { ReactComponent as ArrowDownIcon } from './../../assets/Icons/arrow-down.svg';
import { ReactComponent as DownloadIcon } from './../../assets/Icons/download.svg';
import { ReactComponent as ClientsIcon } from './../../assets/Icons/clients.svg';
import { ReactComponent as EditIcon } from './../../assets/Icons/edit.svg';
import { ReactComponent as SortDownFillIcon } from './../../assets/Icons/sort-down-fill.svg';
import { ReactComponent as SortDownIcon } from './../../assets/Icons/sort-down.svg';
import { ReactComponent as SortUpIcon } from './../../assets/Icons/sort-up.svg';
import { ReactComponent as SortUpFillIcon } from './../../assets/Icons/sort-up-fill.svg';
import { ReactComponent as PartnerLevelIcon } from './../../assets/Icons/partner-level.svg';
import { ReactComponent as ArrowRightIcon } from './../../assets/Icons/arrow-right.svg';
import { ReactComponent as RemoveIcon } from './../../assets/Icons/remove.svg';
import { ReactComponent as AddIcon } from './../../assets/Icons/add.svg';
import { ReactComponent as DeleteIcon } from './../../assets/Icons/delete.svg';
import { ReactComponent as PartnershipIcon } from './../../assets/Icons/partnership.svg';
import { ReactComponent as LoadingClockIcon } from './../../assets/Icons/loading-clock.svg';
import { ReactComponent as CheckRoundedIcon } from './../../assets/Icons/check-rounded.svg';
import { ReactComponent as LoadingArrowsIcon } from './../../assets/Icons/loading-arrows.svg';
import { ReactComponent as WarningIcon } from './../../assets/Icons/warning.svg';
import { ReactComponent as KycSuccessIcon } from './../../assets/Icons/kyc-success.svg';
import { ReactComponent as KycErrorIcon } from './../../assets/Icons/kyc-error.svg';
import { ReactComponent as KycRejectedIcon } from './../../assets/Icons/kyc-rejected.svg';
import { ReactComponent as SendActiveIcon } from './../../assets/Icons/send-active.svg';
import { ReactComponent as ForwardIcon } from './../../assets/Icons/arrow-forward.svg';
import { ReactComponent as HandshakeIcon } from './../../assets/Icons/handshake.svg';

export interface IconProps {
  name: keyof typeof icons;
  height?: number;
  width?: number;
  className?: string;
  dataTest?: string;
  ariaHidden?: boolean;
  onClick?: () => void;
}

export const icons = {
  'arrow-back': BackIcon,
  'arrow-forward': ForwardIcon,
  check: CheckIcon,
  upload: UploadIcon,
  'upload-disabled': UploadDisabledIcon,
  alert: AlertIcon,
  'visibility-on': VisibilityOnIcon,
  'visibility-of': VisibilityOfIcon,
  info: InfoIcon,
  call: CallIcon,
  copy: CopyIcon,
  facebook: FacebookIcon,
  instagram: InstagramIcon,
  linkedin: LinkedinIcon,
  google: GoogleIcon,
  apple: AppleIcon,
  chat: ChatIcon,
  envelope: EnvelopeIcon,
  'burger-menu': BurgerMenuIcon,
  close: CloseIcon,
  search: SearchIcon,
  user: UserIcon,
  folder: FolderIcon,
  support: SupportIcon,
  file: FileIcon,
  'zip-file': ZipFileIcon,
  house: HouseIcon,
  'elipsis-vertical': ElipsisVerticalIcon,
  id: IdIcon,
  person: PersonIcon,
  'finance-hand': FinanceHandIcon,
  calculator: CalculatorIcon,
  'arrow-up': ArrowUpIcon,
  'arrow-down': ArrowDownIcon,
  comment: CommentIcon,
  email: EmailIcon,
  'upload-white': UploadWhiteIcon,
  download: DownloadIcon,
  clients: ClientsIcon,
  edit: EditIcon,
  'sort-up': SortUpIcon,
  'sort-up-fill': SortUpFillIcon,
  'sort-down': SortDownIcon,
  'sort-down-fill': SortDownFillIcon,
  'partner-level': PartnerLevelIcon,
  'arrow-right': ArrowRightIcon,
  remove: RemoveIcon,
  add: AddIcon,
  delete: DeleteIcon,
  partnership: PartnershipIcon,
  'loading-clock': LoadingClockIcon,
  'loading-arrows': LoadingArrowsIcon,
  'check-rounded': CheckRoundedIcon,
  warning: WarningIcon,
  'checkmark-grey': CheckGreyIcon,
  'kyc-success': KycSuccessIcon,
  'kyc-error': KycErrorIcon,
  'kyc-rejected': KycRejectedIcon,
  'send-active': SendActiveIcon,
  handshake: HandshakeIcon
};

const Icon = ({
  name,
  height = 32,
  width = 32,
  className = '',
  onClick,
  ariaHidden = false,
  dataTest
}: IconProps) => {
  const SelectedIcon = icons[name as keyof typeof icons];
  return (
    <SelectedIcon
      height={height}
      width={width}
      onClick={onClick}
      className={`icon ${className}`}
      data-test={dataTest}
      aria-hidden={ariaHidden}
    />
  );
};

export default Icon;
