import { useTranslation } from 'react-i18next';
import TableHead from '../../../../../components/Tables/TableHead/TableHead';
import Text from '../../../../../components/Text/Text';
import Button from '../../../../../components/Button/Button';
import { useEffect, useState } from 'react';
import { getClientProfile } from '../../../../../store/profile/profile.actions';
import { Profile } from '../../../../../store/profile/profile.types';
import { getValueOrPlaceholder } from '../../../../../utils/general.utils';

enum PersonalDetailsTableHeading {
  TITLE = 'title',
  DATA = 'data'
}

interface PersonalDetailsTableProps {
  userId?: string;
  toggle: () => void;
}

const PersonalDetailsTable = ({ userId, toggle }: PersonalDetailsTableProps) => {
  const { t } = useTranslation();
  const tableHeadings = [...Object.values(PersonalDetailsTableHeading).map((elem) => elem)];

  const [user, setUser] = useState<Profile>();

  useEffect(() => {
    getUserById();
  }, [userId]);

  const getUserById = async () => {
    if (userId) {
      const data = await getClientProfile(userId);
      setUser(data);
    }
  };

  const data = [
    {
      name: 'firstName',
      value: user?.profile.firstName
    },
    {
      name: 'lastName',
      value: user?.profile.lastName
    },
    {
      name: 'email',
      value: user?.profile.email
    },
    {
      name: 'phoneNumber',
      value: user?.profile.phoneNumber
    },
    {
      name: 'street',
      value: user?.profile.address?.street
    },
    {
      name: 'houseNumber',
      value: user?.profile.address?.houseNumber
    },
    {
      name: 'town',
      value: user?.profile.address?.municipality
    },
    {
      name: 'zipCode',
      value: user?.profile.address?.postalCode
    },

    {
      name: 'bank',
      value: user?.profile.bankName
    },
    {
      name: 'iban',
      value: user?.profile.iBAN
    }
  ];

  return (
    <div className="personal-details-table-wrapper">
      {user?.profile.email && (
        <TableHead
          values={tableHeadings}
          columnWidths={[50, 50]}
          translationKey="admin.projectDetails.projectReview.personalDetailsTable.table.heading">
          {data.map((elem, ind) => (
            <tr key={ind}>
              <Text as="td" category="label" size="large">
                {t(
                  `admin.projectDetails.projectReview.personalDetailsTable.table.data.${elem.name}`
                )}
              </Text>
              <Text as="td" category="label" size="large">
                {getValueOrPlaceholder(elem.value)}
              </Text>
            </tr>
          ))}
        </TableHead>
      )}
      <Button variation="primary" size="large" dataTest="" onClick={toggle}>
        {t('close')}
      </Button>
    </div>
  );
};

export default PersonalDetailsTable;
