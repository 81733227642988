import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useNavigation from '../../../../../hooks/useNavigation';

import Button from '../../../../../components/Button/Button';
import ContractDocumentItem from '../../../../../components/ContractDetails/ContractDocumentsList/ContractDocumentItem/ContractDocumentItem';
import DatePicker from '../../../../../components/DatePicker/DatePicker';
import Dropdown, { DropdownType } from '../../../../../components/Dropdown/Dropdown';
import TextArea from '../../../../../components/Inputs/TextArea/TextArea';
import Text from '../../../../../components/Text/Text';
import UploadFile from '../../../../UploadOffer/UploadFile/UploadFile';

import { ContractCreate, ContractSignatures, ContractType } from '../../../../../generated';
import { createContractHandler } from '../../../../../store/contracts/contract.actions';
import { formatDate } from '../../../../../utils/formatting.utils';

import './Contract.scss';

const Contract = () => {
  const { t } = useTranslation();
  const { goTo } = useNavigation();

  const [contract, setContract] = useState<ContractCreate>({
    type: 'LOAN',
    expirationDate: '2026-01-01T09:00:00Z',
    signatures: {
      investerra: ''
    }
  });
  const [contractFiles, setContractFiles] = useState<File[]>([]);
  const [contractSignatures, setContractSignatures] = useState<ContractSignatures>({
    investerra: '',
    customer: ''
  });

  const contractTypes: DropdownType[] = Object.values(ContractType).map((value) => ({
    value: value,
    displayValue: t(`admin.projectDetails.contracts.${value}`)
  }));
  const signatures = {
    investerra: contractSignatures.investerra ? new Date(contractSignatures.investerra) : null,
    customer: contractSignatures.customer ? new Date(contractSignatures.customer) : null
  };

  const changeContractTypeHandler = (type: string) => {
    setContract((prev) => {
      return { ...prev, type: type as ContractType };
    });
  };

  const selectFileHandler = (files: FileList | null) => {
    if (!files) return;
    if (files.length > 0) {
      const newFiles = Array.from(files);
      setContractFiles((prev) => [...prev, ...newFiles]);
    }
  };

  const removeFileHandler = (index: number) => {
    const filteredFiles = contractFiles.filter((c) => contractFiles.indexOf(c) !== index);
    setContractFiles(filteredFiles);
  };

  const addCommentHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setContract((prev) => ({ ...prev, comment: e.target.value }));
  };

  const setSignatureDateHandler = (date: Date | null, key: keyof ContractSignatures) => {
    setContractSignatures((prev) => ({
      ...prev,
      [key]: formatDate(date)
    }));
  };

  const onSubmit = async () => {
    const modifiedContract: ContractCreate = { ...contract, ...signatures };
    const createdContract = await createContractHandler(contractFiles, modifiedContract);

    if (createdContract) {
      goTo(`admin/contract/${createdContract.id}`);
    }
  };

  return (
    <div className="contract-wrapper">
      <Text as="h2" category="display">
        {t(`admin.projectDetails.contracts.addContract`)}
      </Text>
      <div className="contract-data">
        <Text as="h3" category="headline" size="large">
          {t(`admin.projectDetails.contracts.addContract`)}
        </Text>
        <div>
          <Text as="p">{t(`admin.projectDetails.contracts.contractType`)}*</Text>
          <Dropdown
            values={contractTypes}
            noValueText={t('selectStatus')}
            getValue={changeContractTypeHandler}
          />
        </div>
        <div className="contract-files">
          <Text as="p">{t('admin.projectDetails.contracts.contractDocumentsAndAnexes')}*</Text>
          <UploadFile handleChangeFiles={selectFileHandler} variation="simple" />
          {!!contractFiles.length &&
            contractFiles.map((c, i) => (
              <ContractDocumentItem
                key={i}
                fileName={c.name}
                onClick={() => {
                  removeFileHandler(i);
                }}
                icon="remove"
              />
            ))}
        </div>
        <TextArea
          name="comment"
          placeholder={t(`admin.projectDetails.contracts.adminComment`)}
          value={contract?.comment}
          onChange={addCommentHandler}
        />
      </div>
      <div className="contract-signatures">
        <Text as="h3" category="headline" size="large">
          {t(`admin.projectDetails.contracts.signatures`)}
        </Text>
        <div>
          <div className="signature-date">
            <Text>{t(`admin.projectDetails.contracts.investerraSignature`)}</Text>
            <DatePicker
              selected={signatures.investerra}
              setSelected={(date) => {
                setSignatureDateHandler(date, 'investerra');
              }}
            />
          </div>
          <div className="signature-date">
            <Text>{t(`admin.projectDetails.contracts.customerSignature`)}</Text>
            <DatePicker
              selected={signatures.customer}
              setSelected={(date) => {
                setSignatureDateHandler(date, 'customer');
              }}
            />
          </div>
        </div>
      </div>
      <Button variation="primary" size="large" dataTest="" onClick={onSubmit}>
        {t('admin.projectDetails.contracts.addContract')}
      </Button>
    </div>
  );
};
export default Contract;
