import { combineReducers } from 'redux';
import simpleReducer from './simpleReducer';
import scrollReducer from './scrollReducer';
import userReducer from './userReducer';
import questionnaireReducer from './questionnaireReducer';
import installationOfferReducer from './installationOfferReducer';

const reducers = combineReducers({
  simpleReducer,
  scrollReducer,
  userReducer,
  questionnaireReducer,
  installationOfferReducer
});

export type RootState = ReturnType<typeof reducers>;

export default reducers;
