import { ProfileCardType } from '../../Profile/Profile';
import ProfileCard from '../../Profile/ProfileCard/ProfileCard';
import Text from '../../../components/Text/Text';
import { useTranslation } from 'react-i18next';
import useNavigation from '../../../hooks/useNavigation';
import './Dashboard.scss';

const Dashboard = () => {
  const { t } = useTranslation();
  const { goTo } = useNavigation();

  const profileCards: ProfileCardType[] = [
    { key: 'projectOverview', icon: 'folder', to: '/admin/projects' },
    { key: 'platformCondition', icon: 'calculator', to: '/admin/platform-terms' },
    // { key: 'invoice', icon: 'finance-hand', to: '/admin/invoices' },
    { key: 'kycReview', icon: 'id', to: '/' },
    { key: 'personalData', icon: 'user', to: '/admin/personal-data' },
    { key: 'clients', icon: 'clients', to: '/admin/clients' },
    { key: 'partners', icon: 'partnership', to: '/admin/partners' }
  ];

  return (
    <>
      <Text as="h1" category="display">
        Admin Dashboard
      </Text>
      <div className="admin-dashboard-cards">
        {profileCards.map((card, index: number) => {
          return (
            <ProfileCard
              key={index}
              title={t(`admin.dashboard.cards.${card.key}.heading`)}
              description={t(`admin.dashboard.cards.${card.key}.text`)}
              buttonText={t(`admin.dashboard.cards.${card.key}.buttonText`)}
              icon={card.icon}
              dataTest={`admin-dashboard-${card.key}-button`}
              onClick={() => {
                goTo(card.to);
              }}
            />
          );
        })}
      </div>
    </>
  );
};

export default Dashboard;
