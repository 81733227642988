import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { t } from 'i18next';

import CompanySelectModal from '../../../components/CompanySelectModal/CompanySelectModal';
import TextInput from '../../../components/Inputs/TextInput/TextInput';
import Search, { SearchType } from '../../../components/Search/Search';
import Text from '../../../components/Text/Text';

import { InstallationOfferCreate } from '../../../generated';
import { RootState } from '../../../reducers/rootReducer';
import { CompanyDetails } from '../../../store/partners/partners.types';
import { InstallationOfferActionType } from '../../../store/offers/offers.types';
import { InstallationOfferEditProps } from '../InstallationOffer';

import { getCompanyDetails, getZefixSuggestionsByName } from '../../../store/zefix/zefix.actions';

import { formatAddress } from '../../../utils/formatting.utils';
import { getHighlightClass } from '../../../utils/questionnaire.utils';
import { validateEmpty, emailValidation } from '../../../utils/validations.utils';

const EditInstallationPartner = ({ isDataMissing }: InstallationOfferEditProps) => {
  const [zefixCompanyData, setZefixCompanyData] = useState<CompanyDetails[]>([]);

  const { installationCompany, installationPartner } = useSelector<RootState>(
    (state) => state.installationOfferReducer
  ) as InstallationOfferCreate;
  const dispatch = useDispatch();

  useEffect(() => {
    if (zefixCompanyData.length === 1) {
      dispatch({
        type: InstallationOfferActionType.CHANGE_INSTALLATION_COMPANY_ZEFIX_DATA,
        payload: {
          name: zefixCompanyData[0].name,
          uid: zefixCompanyData[0].uid,
          address: {
            street: zefixCompanyData[0].address.street,
            houseNumber: zefixCompanyData[0].address.houseNumber,
            municipality: zefixCompanyData[0].address.city,
            postalCode: zefixCompanyData[0].address.swissZipCode
          },
          type: zefixCompanyData[0].legalForm.shortName.de
        }
      });
    }
  }, [zefixCompanyData]);

  const getInstallationPartnerDetails = async (name: SearchType) => {
    const resp = await getCompanyDetails(name.value);
    if (resp) {
      setZefixCompanyData(resp);
    }
  };

  const handleInstallationPartnerDetailsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dispatch({
      type: InstallationOfferActionType.CHANGE_INSTALLATION_PARTNER,
      payload: {
        field: name,
        value
      }
    });
  };

  const handleInstallationCompanyDetailsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dispatch({
      type: InstallationOfferActionType.CHANGE_INSTALLATION_COMPANY,
      payload: {
        field: name,
        value
      }
    });
  };

  const handleClearPartner = () => {
    dispatch({
      type: InstallationOfferActionType.CLEAR_INSTALLATION_COMPANY
    });
  };

  return (
    <>
      <Text as="h3" category="headline" size="large">
        {t('editPartner.partnerDetails')}
      </Text>
      <div className="installation-offer-form-section">
        <TextInput
          value={installationPartner?.firstName}
          name="firstName"
          type="text"
          placeholder={t('editPartner.partnerFirstName')}
          validate={validateEmpty}
          onChange={handleInstallationPartnerDetailsChange}
          className={getHighlightClass(installationPartner?.firstName, isDataMissing)}
          required
        />
        <TextInput
          value={installationPartner?.lastName}
          name="lastName"
          type="text"
          placeholder={t('editPartner.partnerLastName')}
          validate={validateEmpty}
          onChange={handleInstallationPartnerDetailsChange}
          className={getHighlightClass(installationPartner?.lastName, isDataMissing)}
          required
        />
        <Search
          initialValue={installationCompany?.name}
          placeholder={t('editPartner.companyName')}
          fetchData={getZefixSuggestionsByName}
          onOptionSelect={getInstallationPartnerDetails}
          onClear={handleClearPartner}
          className={getHighlightClass(installationCompany?.name, isDataMissing)}
          autocompleteOff
          required
        />
        <TextInput
          value={installationCompany?.email}
          name="email"
          type="email"
          validate={emailValidation}
          placeholder={t('editPartner.companyEmail')}
          onChange={handleInstallationCompanyDetailsChange}
          className={getHighlightClass(installationCompany?.email, isDataMissing)}
          required
        />
        <TextInput
          value={formatAddress(installationCompany?.address)}
          name="company-address"
          type="text"
          placeholder={t('editPartner.companyAddress')}
          disabled
          className={getHighlightClass(installationCompany?.address, isDataMissing)}
          required
        />
      </div>
      {zefixCompanyData.length > 1 && (
        <CompanySelectModal companies={zefixCompanyData} setCompanies={setZefixCompanyData} />
      )}
    </>
  );
};

export default EditInstallationPartner;
