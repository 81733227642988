import { DeepPartial } from 'redux';
import {
  Address,
  CityAndCanton,
  QuestionnaireType,
  GenderType,
  HouseholdType,
  HousingReference,
  MaritalStatus,
  TransportType,
  QuestionnaireFormActionType,
  FinancialData,
  Country,
  EmploymentStatus,
  PermitType
} from '../store/questionnaire/questionnaire.types';
import {
  removeHouseholdDataValues,
  updateApplicanCreditCheckFinancialDetails,
  updateApplicanDetails,
  updateHouseholdData,
  updateHouseholdPartnerData,
  updateKycQuestionnaireData,
  updatePartnerCreditCheckFinancialDetails
} from '../store/questionnaire/questionnaire.actions';
import { BankDetailsType } from '../store/profile/profile.types';

export const initialState: DeepPartial<QuestionnaireType> = {
  kycData: {},
  creditCheckData: {
    applicant: {
      financialData: {
        monthlyIncomeGrossAmountAdditional: [],
        isChurchTaxObligatory: true,
        numberOfMonthlyIncomeMain: 12,
        numberOfMonthlyIncomeAdditional: 12
      },
      beneficialOwner: false
    },
    household: {
      childrenInHouseholdAge: []
    },
    householdPartner: {
      financialData: {
        isChurchTaxObligatory: true,
        monthlyIncomeGrossAmountAdditional: [],
        numberOfMonthlyIncomeMain: 12,
        numberOfMonthlyIncomeAdditional: 12
      }
    }
  }
};

export type QuestionnaireFormAction =
  | {
      type: QuestionnaireFormActionType.REPLACE_STATE;
      payload: DeepPartial<QuestionnaireType>;
    }
  | {
      type: QuestionnaireFormActionType.SET_PROJECT_ID;
      payload: string;
    }
  | {
      type: QuestionnaireFormActionType.CHANGE_MARITAL_STATUS;
      payload: MaritalStatus;
    }
  | {
      type: QuestionnaireFormActionType.CHANGE_GENDER;
      payload: GenderType;
    }
  | {
      type: QuestionnaireFormActionType.CHANGE_BIRTH_DATE;
      payload: Date;
    }
  | {
      type: QuestionnaireFormActionType.CHANGE_BIRTH_PLACE;
      payload: string;
    }
  | {
      type: QuestionnaireFormActionType.CHANGE_NATIONALITY;
      payload: Country;
    }
  | {
      type: QuestionnaireFormActionType.CHANGE_PERMIT_TYPE;
      payload: PermitType;
    }
  | {
      type: QuestionnaireFormActionType.ADD_ADDRESS;
      payload: Address;
    }
  | {
      type: QuestionnaireFormActionType.CHANGE_ADDRESS;
      payload: {
        field: keyof Address;
        value: string;
      };
    }
  | {
      type: QuestionnaireFormActionType.CHANGE_CANTON_AND_CITY;
      payload: CityAndCanton;
    }
  | {
      type: QuestionnaireFormActionType.CHANGE_PHONE_NUMBER;
      payload: string;
    }
  | {
      type: QuestionnaireFormActionType.CHANGE_BANK_DETAILS;
      payload: {
        field: keyof BankDetailsType;
        value: string;
      };
    }
  | {
      type: QuestionnaireFormActionType.CHANGE_RESIDING_SINCE_YEAR;
      payload: number;
    }
  | {
      type: QuestionnaireFormActionType.CHANGE_EMPLOYMENT_STATUS;
      payload: EmploymentStatus;
    }
  | {
      type: QuestionnaireFormActionType.CHANGE_HOUSEHOLD_MONTHLY_INSURANCE_FEE;
      payload: number;
    }
  | {
      type: QuestionnaireFormActionType.CHANGE_RESIDING_SINCE_MONTH;
      payload: number;
    }
  | {
      type: QuestionnaireFormActionType.CHANGE_FINANCIAL_DETAILS;
      payload: {
        field: keyof FinancialData;
        value: number;
      };
    }
  | {
      type: QuestionnaireFormActionType.CHANGE_MONTHLY_INCOME_ADDITONAL;
      payload: number;
    }
  | {
      type: QuestionnaireFormActionType.CHANGE_BENEFICIAL_OWNER;
      payload: boolean;
    }
  | {
      type: QuestionnaireFormActionType.CHANGE_HOUSEHOLD_TYPE;
      payload: HouseholdType;
    }
  | {
      type: QuestionnaireFormActionType.CHANGE_RESIDENCE_TYPE;
      payload: HousingReference;
    }
  | {
      type: QuestionnaireFormActionType.CHANGE_HOUSEHOLD_MEMBERS;
      payload: number;
    }
  | {
      type: QuestionnaireFormActionType.REMOVE_HOUSEHOLD_MEMBERS;
    }
  | {
      type: QuestionnaireFormActionType.CHANGE_CHILD_AGE;
      payload: number[];
    }
  | {
      type: QuestionnaireFormActionType.CHANGE_CHURCH_TAX_OBLIGATION;
      payload: boolean;
    }
  | {
      type: QuestionnaireFormActionType.CHANGE_TRANSPORT_TYPE;
      payload: TransportType;
    }
  | {
      type: QuestionnaireFormActionType.CHANGE_PARTNER_BIRTH_DATE;
      payload: Date;
    }
  | {
      type: QuestionnaireFormActionType.CHANGE_PARTNER_MARITAL_STATUS;
      payload: MaritalStatus;
    }
  | {
      type: QuestionnaireFormActionType.CHANGE_PARTNER_ADDRESS;
      payload: {
        field: keyof Address;
        value: string;
      };
    }
  | {
      type: QuestionnaireFormActionType.SET_PARTNER_ADDRESS_ASS_APPLICANT;
      payload: Address;
    }
  | {
      type: QuestionnaireFormActionType.CHANGE_PARTNER_CITY_AND_CANTON;
      payload: CityAndCanton;
    }
  | {
      type: QuestionnaireFormActionType.CHANGE_PARTNER_EMPLOYMENT_STATUS;
      payload: EmploymentStatus;
    }
  | {
      type: QuestionnaireFormActionType.CHANGE_PARTNER_TRANSPORT_TYPE;
      payload: TransportType;
    }
  | {
      type: QuestionnaireFormActionType.CHANGE_PARTNER_CHURCH_TAX_OBLIGATION;
      payload: boolean;
    }
  | {
      type: QuestionnaireFormActionType.CHANGE_PARTNER_FINANCIAL_DETAILS;
      payload: {
        field: keyof FinancialData;
        value: number;
      };
    }
  | {
      type: QuestionnaireFormActionType.CHANGE_PARTNER_MONTHLY_INCOME_ADDITONAL;
      payload: number[];
    }
  | {
      type: QuestionnaireFormActionType.CHANGE_MONTHLY_INCOME_ADDITIONAL;
      payload: number;
    }
  | {
      type: QuestionnaireFormActionType.REMOVE_PARTNER_MONTHLY_TRANSPORTATION_COSTS;
    };

const questionnaireReducer = (
  state: DeepPartial<QuestionnaireType> = initialState,
  action: QuestionnaireFormAction
) => {
  switch (action.type) {
    case QuestionnaireFormActionType.REPLACE_STATE:
      return {
        ...state,
        kycData: {
          ...state.kycData,
          ...action.payload.kycData
        },
        creditCheckData: {
          ...state.creditCheckData,
          applicant: {
            ...state.creditCheckData?.applicant,
            ...action.payload.creditCheckData?.applicant,
            financialData: {
              ...state.creditCheckData?.applicant?.financialData,
              ...action.payload.creditCheckData?.applicant?.financialData
            }
          },
          household: {
            ...state.creditCheckData?.household,
            ...action.payload.creditCheckData?.household
          },
          householdPartner: {
            ...state.creditCheckData?.householdPartner,
            ...action.payload.creditCheckData?.householdPartner
          }
        }
      };
    case QuestionnaireFormActionType.SET_PROJECT_ID:
      return {
        ...state,
        projectId: action.payload
      };
    case QuestionnaireFormActionType.CHANGE_MARITAL_STATUS:
      return updateApplicanDetails(state, 'maritalStatus', action.payload);
    case QuestionnaireFormActionType.CHANGE_GENDER:
      return updateKycQuestionnaireData(state, 'gender', action.payload);
    case QuestionnaireFormActionType.CHANGE_BIRTH_DATE:
      return updateKycQuestionnaireData(state, 'dateOfBirth', action.payload);
    case QuestionnaireFormActionType.CHANGE_BIRTH_PLACE:
      return updateKycQuestionnaireData(state, 'placeOfBirth', action.payload);
    case QuestionnaireFormActionType.CHANGE_NATIONALITY:
      return updateKycQuestionnaireData(state, 'nationality', action.payload);
    case QuestionnaireFormActionType.CHANGE_PERMIT_TYPE:
      return updateApplicanDetails(state, 'permitType', action.payload);
    case QuestionnaireFormActionType.CHANGE_PHONE_NUMBER:
      return updateKycQuestionnaireData(state, 'phoneNumber', action.payload);
    case QuestionnaireFormActionType.CHANGE_BANK_DETAILS:
      return {
        ...state,
        kycData: {
          ...state.kycData,
          bankDetails: {
            ...state.kycData?.bankDetails,
            [action.payload.field]: action.payload.value
          }
        }
      };
    case QuestionnaireFormActionType.CHANGE_BENEFICIAL_OWNER:
      return updateApplicanDetails(state, 'beneficialOwner', action.payload);
    case QuestionnaireFormActionType.CHANGE_HOUSEHOLD_TYPE:
      return updateHouseholdData(state, 'householdType', action.payload);
    case QuestionnaireFormActionType.CHANGE_RESIDENCE_TYPE:
      return updateApplicanDetails(state, 'residenceType', action.payload);
    case QuestionnaireFormActionType.CHANGE_HOUSEHOLD_MEMBERS:
      return updateHouseholdData(state, 'householdNumberOfSupportedGrownUps', action.payload);
    case QuestionnaireFormActionType.REMOVE_HOUSEHOLD_MEMBERS:
      return removeHouseholdDataValues(state, 'householdNumberOfSupportedGrownUps');
    case QuestionnaireFormActionType.CHANGE_CHILD_AGE:
      return updateHouseholdData(state, 'childrenInHouseholdAge', action.payload);
    case QuestionnaireFormActionType.ADD_ADDRESS:
      return updateKycQuestionnaireData(state, 'address', action.payload);
    case QuestionnaireFormActionType.CHANGE_ADDRESS:
      return {
        ...state,
        kycData: {
          ...state.kycData,
          address: {
            ...state.kycData?.address,
            [action.payload.field]: action.payload.value
          } as Address
        }
      };
    case QuestionnaireFormActionType.CHANGE_CANTON_AND_CITY:
      return {
        ...state,
        kycData: {
          ...state.kycData,
          address: {
            ...state.kycData?.address,
            canton: action.payload.canton,
            municipality: action.payload.municipality,
            country: action.payload.country
          } as Address
        }
      };
    case QuestionnaireFormActionType.CHANGE_RESIDING_SINCE_YEAR:
      return updateApplicanDetails(state, 'residingSinceYear', action.payload);
    case QuestionnaireFormActionType.CHANGE_HOUSEHOLD_MONTHLY_INSURANCE_FEE:
      return updateHouseholdData(state, 'householdMonthlyInsuranceFee', action.payload);
    case QuestionnaireFormActionType.CHANGE_RESIDING_SINCE_MONTH:
      return updateApplicanDetails(state, 'residingSinceMonth', action.payload);
    case QuestionnaireFormActionType.CHANGE_EMPLOYMENT_STATUS:
      return updateApplicanDetails(state, 'employmentStatus', action.payload);
    case QuestionnaireFormActionType.CHANGE_TRANSPORT_TYPE:
      return updateApplicanCreditCheckFinancialDetails(state, 'transportationType', action.payload);
    case QuestionnaireFormActionType.CHANGE_MONTHLY_INCOME_ADDITIONAL:
      return {
        ...state,
        creditCheckData: {
          ...state.creditCheckData,
          applicant: {
            ...state.creditCheckData?.applicant,
            financialData: {
              ...state.creditCheckData?.applicant?.financialData,
              monthlyIncomeGrossAmountAdditional: action.payload
            }
          }
        }
      };
    case QuestionnaireFormActionType.CHANGE_CHURCH_TAX_OBLIGATION:
      return updateApplicanCreditCheckFinancialDetails(
        state,
        'isChurchTaxObligatory',
        action.payload
      );
    case QuestionnaireFormActionType.CHANGE_FINANCIAL_DETAILS:
      return updateApplicanCreditCheckFinancialDetails(
        state,
        action.payload.field,
        action.payload.value
      );
    case QuestionnaireFormActionType.CHANGE_PARTNER_BIRTH_DATE:
      return updateHouseholdPartnerData(state, 'dateOfBirth', action.payload);
    case QuestionnaireFormActionType.CHANGE_PARTNER_MARITAL_STATUS:
      return updateHouseholdPartnerData(state, 'maritalStatus', action.payload);
    case QuestionnaireFormActionType.CHANGE_PARTNER_ADDRESS:
      return {
        ...state,
        creditCheckData: {
          ...state.creditCheckData,
          householdPartner: {
            ...state.creditCheckData?.householdPartner,
            address: {
              ...state.creditCheckData?.householdPartner?.address,
              [action.payload.field]: action.payload.value
            }
          }
        }
      };
    case QuestionnaireFormActionType.SET_PARTNER_ADDRESS_ASS_APPLICANT:
      return updateHouseholdPartnerData(state, 'address', action.payload);
    case QuestionnaireFormActionType.CHANGE_PARTNER_CITY_AND_CANTON:
      return {
        ...state,
        creditCheckData: {
          ...state.creditCheckData,
          householdPartner: {
            ...state.creditCheckData?.householdPartner,
            address: {
              ...state.creditCheckData?.householdPartner?.address,
              canton: action.payload.canton,
              municipality: action.payload.municipality,
              country: action.payload.country
            }
          }
        }
      };
    case QuestionnaireFormActionType.CHANGE_PARTNER_EMPLOYMENT_STATUS:
      return updateHouseholdPartnerData(state, 'employmentStatus', action.payload);
    case QuestionnaireFormActionType.CHANGE_PARTNER_CHURCH_TAX_OBLIGATION:
      return updatePartnerCreditCheckFinancialDetails(
        state,
        'isChurchTaxObligatory',
        action.payload
      );
    case QuestionnaireFormActionType.CHANGE_PARTNER_TRANSPORT_TYPE:
      return updatePartnerCreditCheckFinancialDetails(state, 'transportationType', action.payload);
    case QuestionnaireFormActionType.CHANGE_PARTNER_FINANCIAL_DETAILS:
      return updatePartnerCreditCheckFinancialDetails(
        state,
        action.payload.field,
        action.payload.value
      );
    case QuestionnaireFormActionType.CHANGE_PARTNER_MONTHLY_INCOME_ADDITONAL:
      return {
        ...state,
        creditCheckData: {
          ...state.creditCheckData,
          householdPartner: {
            ...state.creditCheckData?.householdPartner,
            financialData: {
              ...state.creditCheckData?.householdPartner?.financialData,
              monthlyIncomeGrossAmountAdditional: action.payload
            }
          }
        }
      };
    case QuestionnaireFormActionType.REMOVE_PARTNER_MONTHLY_TRANSPORTATION_COSTS:
      return {
        ...state,
        creditCheckData: {
          ...state.creditCheckData,
          householdPartner: {
            ...state.creditCheckData?.householdPartner,
            financialData: {
              ...state.creditCheckData?.householdPartner?.financialData,
              monthlyTransportationCosts: undefined
            }
          }
        }
      };
    default:
      return state;
  }
};

export default questionnaireReducer;
