import './Text.scss';

export interface TextProps extends React.HTMLAttributes<HTMLDivElement> {
  as?:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'p'
    | 'span'
    | 'label'
    | 'div'
    | 'li'
    | 'td'
    | 'th'
    | 'option';
  children?: React.ReactNode;
  className?: string;
  htmlFor?: string;
  category?: 'display' | 'headline' | 'title' | 'body' | 'label';
  size?: 'small' | 'medium' | 'large';
  variety?: number;
  italic?: boolean;
  style?: React.CSSProperties;
  color?: string;
  disabled?: boolean;
  dataTestId?: string;
  onClick?: () => void;
}

const Text = ({
  as = 'div',
  children,
  className = '',
  htmlFor,
  category = 'body',
  size = 'medium',
  variety = 0,
  italic = false,
  style = {},
  disabled = false,
  dataTestId,
  onClick
}: TextProps) => {
  const Tag = `${as}` as keyof JSX.IntrinsicElements;
  return (
    <Tag
      style={{ ...style }}
      className={`sak-text ${category} ${size} ${variety ? `v${variety}` : ''} ${
        italic ? `italic` : ''
      } ${className} ${disabled ? 'disabled' : ''}`}
      data-testid={dataTestId}
      htmlFor={htmlFor}
      onClick={onClick}>
      {children}
    </Tag>
  );
};

export default Text;
