import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Profile } from '../../../store/profile/profile.types';
import { useSelector } from 'react-redux';
import { RootState } from '../../../reducers/rootReducer';
import { User } from '../../../store/user/types';
import TabContent from '../../../components/Tabs/TabContent/TabContent';
import Tabs from '../../../components/Tabs/Tabs';
import Text from '../../../components/Text/Text';
import { getProfile } from '../../../store/profile/profile.actions';
import CompanyData from './CompanyData/CompanyData';
import SecurityInfo from '../../Profile/PersonalData/SecurityInfo/SecurityInfo';

interface TabsRenderType {
  key: string;
  comp: JSX.Element;
}

const PartnerAccount = () => {
  const { t } = useTranslation();
  const user = useSelector<RootState>((state) => state.userReducer) as User;

  const [personalData, setPersonalData] = useState<Profile>({} as Profile);

  const showChangePassword = true;
  //TODO STR1-231: Find a way to read if the IDP was used for reg: auth.currentUser?.providerData.some((e) => e.providerId === 'password');

  useEffect(() => {
    const getPartnerData = async () => {
      const data = await getProfile();
      data && setPersonalData(data);
    };

    getPartnerData();
  }, [user.id]);

  const tabsRenderProps = useMemo(() => {
    const tabs: TabsRenderType[] = [
      {
        key: 'companyData',
        comp: <CompanyData />
      }
    ];
    if (showChangePassword)
      tabs.splice(1, 0, {
        key: 'security',
        comp: <SecurityInfo />
      });

    return tabs;
  }, [user, personalData]);

  return (
    <>
      <Text as="h1" category="display" className="personal-data-title">
        {t('partners.dashboard.cards.myAccount.heading')}
      </Text>
      <Tabs>
        {tabsRenderProps.map((tab, index) => {
          return (
            <TabContent title={t(`partners.account.${tab.key}.title`)} key={index}>
              {tab.comp}
            </TabContent>
          );
        })}
      </Tabs>
    </>
  );
};

export default PartnerAccount;
