import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import AddressForm from '../../../components/AddressForm/AddressForm';
import Checkbox from '../../../components/Checkbox/Checkbox';
import DatePicker from '../../../components/DatePicker/DatePicker';
import Text from '../../../components/Text/Text';
import MaritalStatusList from '../MaritalStatusList/MaritalStatusList';

import { RootState } from '../../../reducers/rootReducer';
import {
  Address,
  CityAndCanton,
  Country,
  HouseholdType,
  MaritalStatus,
  QuestionnaireFormProps,
  QuestionnaireFormActionType,
  QuestionnaireType
} from '../../../store/questionnaire/questionnaire.types';

import { getCityAndCanton } from '../../../utils/general.utils';
import { getHighlightClass } from '../../../utils/questionnaire.utils';

import '../Questionnaire.scss';

const LifePartnerDetails = ({ isDataMissing }: QuestionnaireFormProps) => {
  const { t } = useTranslation();
  const questionnaire = useSelector<RootState>(
    (state) => state.questionnaireReducer
  ) as QuestionnaireType;
  const dispatch = useDispatch();

  const [addressAsApplicant, setAddressAsApplicant] = useState<boolean>(false);

  const dateOfBirth = questionnaire.creditCheckData.householdPartner?.dateOfBirth ?? null;
  const lifePartnerMaritalStatus =
    questionnaire.creditCheckData?.householdPartner.maritalStatus ?? '';
  const applicantMaritalStatus = questionnaire.creditCheckData?.applicant.maritalStatus ?? '';
  const householdType = questionnaire.creditCheckData?.household?.householdType ?? '';
  const applicantAddress = questionnaire.kycData.address;
  const householdPartnerAddress = questionnaire.creditCheckData.householdPartner.address;

  const hideLifePartnerMaritalStatus =
    (applicantMaritalStatus === MaritalStatus.MARRIED ||
      applicantMaritalStatus === MaritalStatus.REGISTERED) &&
    householdType === HouseholdType.MARRIED_COUPLE;

  useEffect(() => {
    const fetchCityAndCanton = async () => {
      if (householdPartnerAddress?.postalCode?.length === 4) {
        const res = (await getCityAndCanton(householdPartnerAddress?.postalCode)) as CityAndCanton;
        res &&
          dispatch({
            type: QuestionnaireFormActionType.CHANGE_PARTNER_CITY_AND_CANTON,
            payload: { ...res, country: Country.SWITZERLAND }
          });
      }
    };

    fetchCityAndCanton();
  }, [householdPartnerAddress?.postalCode]);

  useEffect(() => {
    if (
      applicantAddress?.street &&
      JSON.stringify(applicantAddress) === JSON.stringify(householdPartnerAddress)
    ) {
      setAddressAsApplicant(true);
    }
  }, []);

  const changeAddressHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: QuestionnaireFormActionType.CHANGE_PARTNER_ADDRESS,
      payload: {
        field: e.target.name as keyof Address,
        value: e.target.value
      }
    });
  };

  const addressAsApplicantHandler = () => {
    if (!addressAsApplicant) {
      setAddressAsApplicant(true);
      dispatch({
        type: QuestionnaireFormActionType.SET_PARTNER_ADDRESS_ASS_APPLICANT,
        payload: applicantAddress
      });
    } else {
      setAddressAsApplicant(false);
    }
  };

  const changeMaritalStatusHandler = (value: string) => {
    dispatch({
      type: QuestionnaireFormActionType.CHANGE_PARTNER_MARITAL_STATUS,
      payload: value as MaritalStatus
    });
  };

  return (
    <form className="credit-check-form">
      {/* TODO STR1-752: Make a component and use for both applicant and household partner */}
      <div>
        <Text as="label" category="headline">
          {t('creditCheck.lifePartner.lifePartnerIn')}
        </Text>
        <DatePicker
          selected={dateOfBirth}
          label={t('dateOfBirth')}
          setSelected={(date) =>
            dispatch({
              type: QuestionnaireFormActionType.CHANGE_PARTNER_BIRTH_DATE,
              payload: date
            })
          }
          required
          className={getHighlightClass(dateOfBirth, isDataMissing)}
        />
      </div>
      {!hideLifePartnerMaritalStatus && (
        <MaritalStatusList
          label={t('creditCheck.lifePartner.maritalStatusPartner')}
          handleCheck={changeMaritalStatusHandler}
          checkedStatus={lifePartnerMaritalStatus}
          className={getHighlightClass(lifePartnerMaritalStatus, isDataMissing)}
        />
      )}
      <div>
        <Text as="label" category="headline">
          {t('creditCheck.lifePartner.placeOfResidence')}
        </Text>
        <Checkbox
          checked={addressAsApplicant}
          label={t('creditCheck.lifePartner.jointPlaceOfResidence')}
          onClick={addressAsApplicantHandler}
        />
        <AddressForm
          address={addressAsApplicant ? applicantAddress : (householdPartnerAddress as Address)}
          onChange={changeAddressHandler}
          isDisabled={addressAsApplicant}
          required
          className={getHighlightClass(householdPartnerAddress, isDataMissing)}
        />
      </div>
    </form>
  );
};

export default LifePartnerDetails;
