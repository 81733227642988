import { BuildingAddress } from '../../types/sepMap.types';
import { DeepPartial } from 'redux';
import { Partner } from '../partners/partners.types';
import { Project } from '../project/project.types';
import { Profile } from '../profile/profile.types';
import { Sort } from '../../types/util.types';

export enum OfferStatus {
  PENDING = 'PENDING',
  CREATED = 'CREATED',
  ACCEPTED = 'ACCEPTED',
  DECLINED = 'DECLINED',
  EXPIRED = 'EXPIRED',
  OFFER_IN_DELIVERY = 'OFFER_IN_DELIVERY',
  OFFER_COMPLETED = 'OFFER_COMPLETED'
}

export interface OfferResponse {
  project: DeepPartial<Project>;
  offers: PartnerOffer[];
}

export interface Offer {
  id: number;
  area: number;
  status: OfferStatus;
  performance: number;
  offeredPrice: number;
  subsidy: number;
  issuanceDate: Date;
  validThru: Date;
  deliveryDate: Date;
  description: string;
  documentUrl?: string;
  contactPerson?: string;
}

export interface PartnerOffer extends Offer {
  partner: Partner;
  contact: Profile;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UpdatePartnerOffer
  extends Pick<
    PartnerOffer,
    | 'area'
    | 'performance'
    | 'offeredPrice'
    | 'subsidy'
    | 'issuanceDate'
    | 'validThru'
    | 'deliveryDate'
    | 'description'
    | 'contact'
  > {}

export interface PartnerOfferView {
  owner: Partial<Profile>;
  address: BuildingAddress;
  offer: Partial<PartnerOffer>;
}

export interface PartnerInfo {
  partner: Pick<Partner, 'name' | 'partnerLocations'>;
  contact: PartnerContact;
}

export interface PartnerContact {
  name: string;
  email: string;
  phone: string;
}

export interface GetOffers {
  size: number;
  page: number;
  sort: Sort[];
  priceFrom?: number;
  priceTo?: number;
  dateRange?: DateRange;
  keyword?: string;
  status?: OfferStatus;
}

export interface DateRange {
  dateFrom?: string;
  dateTo?: string;
}

export interface OfferListItem {
  id: number;
  status: OfferStatus;
  sentTimestamp: Date;
  offeredPrice: number;
  project: Project;
}

export interface OffersList {
  offers: OfferListItem[];
  size: number;
  totalPages: number;
  totalItems: number;
  pageNumber: number;
  empty: boolean;
}

export interface UpploadScanOffer {
  documentUrl: string;
  percentage: number;
}

export enum InstallationOfferActionType {
  REPLACE_STATE = 'REPLACE_STATE',
  CHANGE_PERSONAL_DETAILS = 'CHANGE_PERSONAL_DETAILS',
  CHANGE_BUILDING = 'CHANGE_BUILDING',
  CHANGE_INSTALLATION_PARTNER = 'CHANGE_INSTALLATION_PARTNER',
  CHANGE_INSTALLATION_COMPANY = 'CHANGE_INSTALLATION_COMPANY',
  CHANGE_INSTALLATION_COMPANY_ZEFIX_DATA = 'CHANGE_INSTALLATION_COMPANY_ZEFIX_DATA',
  CLEAR_INSTALLATION_COMPANY = 'CLEAR_INSTALLATION_COMPANY',
  CHANGE_OFFER_DATE = 'CHANGE_OFFER_DATE',
  CHANGE_OFFER_PRICE = 'CHANGE_OFFER_PRICE',
  CHANGE_LOAN_DETAILS = 'CHANGE_LOAN_DETAILS',
  CHANGE_FILE_PATH = 'CHANGE_FILE_PATH'
}
